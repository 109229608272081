import { Action, Module, Mutation, VuexModule } from 'vuex-module-decorators'

import api from '@/api'
import { InvoiceLine, InvoiceLineControllerApi } from '@/client/invoices'
import { i18n } from '@/plugins/i18n'
import type { ICurrentPageOpts, ObjectKeyAsAny } from '@/types/app'

import type { ICost, ICosts } from './types'
const { VUE_APP_BASE_URL } = process.env
const BASE_URL = VUE_APP_BASE_URL.slice(0, VUE_APP_BASE_URL.length - 1)

const invoiceLineControllerApi = new InvoiceLineControllerApi(undefined, BASE_URL)
@Module({
  namespaced: true
})
export class CostsModule extends VuexModule {
  costs: ICosts | null = null
  cost: ICost | null = null
  costTypes: ObjectKeyAsAny | null = null

  @Mutation
  setCosts (costs: ICosts) {
    this.costs = costs
  }

  @Mutation
  setCostTypes (costTypes: Array<string>) {
    this.costTypes = costTypes
  }

  @Action({ rawError: true })
  async getCosts (opts: ICurrentPageOpts): Promise<void> {
    const res = await api.costs.getCosts(this.context.rootGetters['auth/jwt'], opts) as ICosts
    if (res && res.lineCosts) {
      this.context.commit('setCosts', res)
      return
    }

    this.context.commit('setCosts', null)
  }

  @Action({ rawError: true })
  async getCostsByProductId (opts: { productId: number} & ICurrentPageOpts): Promise<void> {
    const res = await api.costs.getCostsByProductId(this.context.rootGetters['auth/jwt'], opts) as ICosts
    if (res && res.lineCosts) {
      this.context.commit('setCosts', res)
      return
    }

    this.context.commit('setCosts', null)
  }

  @Action({ rawError: true })
  async getCostTypes (): Promise<void> {
    const token = this.context.rootGetters['auth/jwt']
    const res = await api.costs.getCostTypes(token, this.context.rootGetters['user/id']) as Array<string>
    this.context.commit('setCostTypes', res)
  }

  @Action({ rawError: true })
  async alterCost (opts: { line: ICost}): Promise<void> {
    await api.costs.alterCost(
      this.context.rootGetters['auth/jwt'],
      {
        invoiceId: opts.line.invoice.id,
        id: opts.line.invoiceLineId,
        isExcludedFromGeneralCostsData: !!opts.line.isExcludedFromGeneralCostsData,
        userId: this.context.rootGetters['user/id']
      }
    )
  }

  @Action({ rawError: true })
  async excludeBatchCostLines (opts: { lines: InvoiceLine[] }): Promise<void> {
    const token = this.context.rootGetters['auth/jwt']
    const userId = this.context.rootGetters['user/id']
    try {
      const requestOpts = {
        headers: { Authorization: `Bearer ${token}` }
      }
      await invoiceLineControllerApi.updateInvoiceLines(userId, opts.lines, requestOpts)
    } catch (e) {
      this.context.dispatch('toasts/addError', i18n.t('pages.errors.422.description'), { root: true }
      )
    }
  }
}
