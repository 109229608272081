
import { Component, Vue } from 'vue-property-decorator'
@Component
export default class EmailVerifyToast extends Vue {
  value = false

  myClick () {
    this.$emit('emailVerifyEvent')
  }
}
