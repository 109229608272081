import * as Sentry from '@sentry/vue'
import { Action, Module, Mutation, VuexModule } from 'vuex-module-decorators'

import api from '@/api'
import { type Document, DocumentControllerApi, DocumentDocumentTypeEnum, DocumentEntityTypeEnum, FetchAllDocumentsForUserByEntityIdEntityTypeEnum, PageDocument } from '@/client/documents'
import type { AccountCode, CreateInvoiceFromPoOrSupplierDto, Invoice, InvoicePaymentDto, Pageable, PageInvoicePaymentDto, PagePaymentDto, PaymentConfirmationDto } from '@/client/invoices'
import { InvoiceControllerApi, PaymentsControllerApi } from '@/client/invoices'
import { type ImportGoogleSheetDto, ProductControllerApi, type ProductSummaryDto } from '@/client/products'
import { i18n } from '@/plugins/i18n'
import type { ICurrentPageOpts, ObjectKeyAsAny } from '@/types/app'

import type {
  IDocument,
  IInvoice,
  IInvoiceLineForm,
  IInvoices,
  IInvoiceUploadFormData
} from './types'

const { VUE_APP_BASE_URL } = process.env
const BASE_URL = VUE_APP_BASE_URL.slice(0, VUE_APP_BASE_URL.length - 1)

const paymentsControllerApi = new PaymentsControllerApi(undefined, BASE_URL)
const productControllerApi = new ProductControllerApi(undefined, BASE_URL)
const invoiceControllerApi = new InvoiceControllerApi(undefined, BASE_URL)
const documentControllerApi = new DocumentControllerApi(undefined, BASE_URL)
@Module({
  namespaced: true
})
export class InvoicesModule extends VuexModule {
  content: IInvoices | null = null
  invoice: IInvoice | null = null
  payables: PagePaymentDto | null = null
  bills: PageInvoicePaymentDto | null = null
  invoicePayment: InvoicePaymentDto = {
    unixDueDate: 0,
    payments: []
    // assignedToUser: {}
  }

  // share costTypes between different forms(invoice, invoiceLine)
  payFromAccountCode: AccountCode | [] = []
  costTypes: ObjectKeyAsAny | null = null
  paymentStatuses: ObjectKeyAsAny | null = null
  accountCode: AccountCode | [] = []
  accounts: { code: string, name: string, accountId: string }[] = []
  xeroContacts: any[] = []
  xeroPayments: any = null

  @Mutation
  setPayables (content: PagePaymentDto) {
    this.payables = content
  }

  @Mutation
  setBills (content: PageInvoicePaymentDto) {
    this.bills = content
  }

  @Mutation
  setInvoices (content: IInvoices) {
    if (!content) {
      this.content = null
    } else {
      const oldContent = this.content || {}
      this.content = { ...oldContent, ...content }
    }
  }

  @Mutation
  setInvoicePayment (invoicePayment: InvoicePaymentDto) {
    if (!invoicePayment) {
      this.invoicePayment = {
        unixDueDate: 0,
        // assignedToUser: {},
        payments: []
      }
    } else {
      this.invoicePayment = invoicePayment
    }
  }

  @Mutation
  setCostTypes (content: ObjectKeyAsAny) {
    this.costTypes = content
  }

  @Mutation
  setAccountCode (content: AccountCode) {
    this.accountCode = content
  }

  @Mutation
  setPayFromAccountCode (accountCode: AccountCode) {
    this.payFromAccountCode = accountCode
  }

  @Mutation
  setPaymentStatuses (content: ObjectKeyAsAny) {
    this.paymentStatuses = content
  }

  @Mutation
  setInvoice (invoice: IInvoice) {
    this.invoice = invoice
  }

  @Mutation
  setDocument (params: { invoiceId: number, doc: IDocument }) {
    const { invoiceId, doc } = params
    const targetInvoice = this.content?.content.find(invoice => invoice.id === invoiceId)
    if (!targetInvoice) throw new Error('Invoice not found')

    targetInvoice.document = doc
  }

  @Mutation
  setAccounts (accounts: { code: string, name: string, accountId: string }[]) {
    this.accounts = accounts
  }

  @Mutation
  setXeroPayments (payments: any) {
    this.xeroPayments = payments
  }

  @Action({ rawError: true })
  async getInvoice (invoiceId: number) {
    const res = await api.invoices.getInvoice(this.context.rootGetters['auth/jwt'], invoiceId, this.context.rootGetters['user/id'])
    this.context.commit('setInvoice', res)
  }

  @Action({ rawError: true })
  async refreshInvoices (invoice: Invoice) {
    this.context.commit('setInvoice', invoice)
  }

  @Action({ rawError: true })
  async getInvoicePayment (invoicePaymentId: number): Promise<void> {
    const userId = this.context.rootGetters['user/id']
    const token = this.context.rootGetters['auth/jwt']
    try {
      const requestOpts = {
        headers: { Authorization: `Bearer ${token}` }
      }
      const res = await paymentsControllerApi.fetchInvoicePaymentForUserById(userId, invoicePaymentId, requestOpts)
      this.context.commit('setInvoicePayment', res.data)
    } catch (e) {
      Sentry.captureException(e)
      this.context.commit('setInvoicePayment', null)
      return Promise.reject(e)
    }
  }

  @Action({ rawError: true })
  async fetchPaymentsForUser (opts: Pageable & { sort?: Array<any>, search?: any }): Promise<void> {
    const userId = this.context.rootGetters['user/id']
    const token = this.context.rootGetters['auth/jwt']
    const data = await api.invoices.fetchPaymentsForUser(token, {
      userId,
      page: opts?.pageNumber,
      size: opts?.pageSize,
      sort: opts?.sort,
      search: opts?.search
    })
    this.context.commit('setPayables', data)
  }

  @Action({ rawError: true })
  async getPaymentStatuses (userId: number): Promise<void> {
    const token = this.context.rootGetters['auth/jwt']
    try {
      const requestOpts = {
        headers: { Authorization: `Bearer ${token}` }
      }
      const res = await paymentsControllerApi.getPaymentStatusesForUser(userId, requestOpts)
      this.context.commit('setPaymentStatuses', res.data)
    } catch (e) {
      Sentry.captureException(e)
    }
  }

  @Action({ rawError: true })
  async getAccountCode (id: number): Promise<void> {
    const token = this.context.rootGetters['auth/jwt']
    const userId = id ?? this.context.rootGetters['user/id']
    try {
      const requestOpts = {
        headers: { Authorization: `Bearer ${token}` }
      }
      const res = await paymentsControllerApi.getInvoiceAccountCodesForUser(userId, requestOpts)
      this.context.commit('setAccountCode', res.data)
    } catch (e) {
      Sentry.captureException(e)
    }
  }

  @Action({ rawError: true })
  async getPayFromAccountCode (id: number): Promise<void> {
    const token = this.context.rootGetters['auth/jwt']
    const userId = id ?? this.context.rootGetters['user/id']
    try {
      const requestOpts = {
        headers: { Authorization: `Bearer ${token}` }
      }
      const res = await paymentsControllerApi.getPaymentAccountCodesForUser(userId, requestOpts)
      this.context.commit('setPayFromAccountCode', res.data)
    } catch (e) {
      Sentry.captureException(e)
    }
  }

  @Action({ rawError: true })
  async fetchInvoicePaymentsForUser (opts: Pageable & { userId: number, sort?: Array<any>, search?: string }): Promise<void> {
    // const userId = this.context.rootGetters['user/id']
    const token = this.context.rootGetters['auth/jwt']
    const data = await api.invoices.fetchInvoicePaymentsForUser(token, {
      userId: opts?.userId,
      page: opts?.pageNumber,
      size: opts?.pageSize,
      sort: opts?.sort,
      search: opts?.search
    })
    this.context.commit('setBills', data)
  }

  @Action
  async getXeroPayments (xeroId: string): Promise<any> {
    const res = await api.invoices.getXeroPayments(this.context.rootGetters['auth/jwt'], xeroId, this.context.rootGetters['user/id'])
    this.context.commit('setXeroPayments', res)
  }

  @Action({ rawError: true })
  async getCostTypes (): Promise<void> {
    const token = this.context.rootGetters['auth/jwt']
    const res = await api.invoices.getCostTypes(token, this.context.rootGetters['user/id']) as Array<string>
    this.context.commit('setCostTypes', res)
  }

  @Action({ rawError: true })
  async deleteInvoiceLine (lineId: number, invoiceId?: number): Promise<void> {
    const id = invoiceId || this.invoice?.id
    if (!id) {
      await this.context.dispatch(
        'toasts/addError',
        i18n.t('pages.errors.invoices.noInvoice'),
        { root: true }
      )
      return
    }

    await api.invoices.deleteInvoiceLine(this.context.rootGetters['auth/jwt'], id, lineId, this.context.rootGetters['user/id'])
  }

  @Action({ rawError: true })
  async deleteInvoice (invoiceId: number): Promise<void> {
    await api.invoices.deleteInvoice(this.context.rootGetters['auth/jwt'], invoiceId, this.context.rootGetters['user/id'])
    this.context.commit('setInvoice', null)
  }

  @Action({ rawError: true })
  async getInvoices (opts: ICurrentPageOpts) {
    if (!opts || !opts.userId) opts = { ...opts, userId: this.context.rootGetters['user/id'] }
    try {
      const res = await api.invoices.getInvoices(this.context.rootGetters['auth/jwt'], opts)
      this.context.commit('setInvoices', res)
    } catch (err) {
      Sentry.captureException(err)
      this.context.commit('setInvoices', null)
    }
  }

  @Action({ rawError: true })
  async uploadInvoice (formData: IInvoiceUploadFormData): Promise<IInvoice> {
    const res = await api.invoices.uploadInvoice(this.context.rootGetters['auth/jwt'], formData)
    return res
  }

  @Action({ rawError: true })
  async saveInvoiceLine (formData: IInvoiceLineForm) {
    return await api.invoices.saveInvoiceLine(this.context.rootGetters['auth/jwt'], formData, this.context.rootGetters['user/id'])
  }

  @Action({ rawError: true })
  async saveInvoice (invoice: IInvoice): Promise<IInvoice | null> {
    const updatedInvoice = await api.invoices.save(this.context.rootGetters['auth/jwt'], invoice, this.context.rootGetters['user/id'])

    await this.context.commit('setInvoice', updatedInvoice)
    return updatedInvoice
  }

  @Action({ rawError: true })
  async getXeroAccounts (id: number) {
    const res = await api.invoices.getXeroAccounts(this.context.rootGetters['auth/jwt'], id)
    if (res) {
      const accounts = res.Accounts.map(({ Code, AccountID, Name }: any) => ({
        code: Code,
        accountId: AccountID,
        name: Name
      }))
      this.context.commit('setAccounts', accounts)
    }
  }

  // @Action({ rawError: true })
  // async getXeroContacts (id: number) {
  //   const res = await api.invoices.getXeroContacts(this.context.rootGetters['auth/jwt'], id)
  // }

  @Action({ rawError: true })
  async saveXeroBill (args: { userId: number, bill: any }): Promise<any> {
    const { userId, bill } = args
    const xeroBill = await api.invoices.saveXeroBill(this.context.rootGetters['auth/jwt'], userId, bill)
    return xeroBill
  }

  @Action({ rawError: true })
  async createInvoicePayment (invoicePaymentDto: InvoicePaymentDto): Promise<void> {
    const token = this.context.rootGetters['auth/jwt']
    const userId = this.context.rootGetters['user/id']
    try {
      const requestOpts = {
        headers: { Authorization: `Bearer ${token}` }
      }
      await paymentsControllerApi.createInvoicePayment(userId, invoicePaymentDto, requestOpts)
    } catch (e) {
      Sentry.captureException(e)
    }
  }

  @Action({ rawError: true })
  async replaceInvoicePayment (invoicePaymentDto: InvoicePaymentDto): Promise<any> {
    const token = this.context.rootGetters['auth/jwt']
    const userId = this.context.rootGetters['user/id']
    const { id } = invoicePaymentDto
    try {
      const requestOpts = {
        headers: { Authorization: `Bearer ${token}` }
      }
      const res = await paymentsControllerApi.replaceInvoicePayment(userId, id as number, invoicePaymentDto, requestOpts)
      return res.data as InvoicePaymentDto
    } catch (e) {
      Sentry.captureException(e)
    }
  }

  @Action({ rawError: true })
  async deleteInvoicePayment (invoicePaymentId: number): Promise<void> {
    const token = this.context.rootGetters['auth/jwt']
    const userId = this.context.rootGetters['user/id']
    try {
      const requestOpts = {
        headers: { Authorization: `Bearer ${token}` }
      }
      await paymentsControllerApi.deleteInvoicePayment(userId, invoicePaymentId, requestOpts)
    } catch (e) {
      Sentry.captureException(e)
    }
  }

  @Action({ rawError: true })
  async updatePaymentEntry (opts: { invoicePaymentId: number, paymentConfirmationDto?: PaymentConfirmationDto }): Promise<any> {
    const token = this.context.rootGetters['auth/jwt']
    const userId = this.context.rootGetters['user/id']
    try {
      const requestOpts = {
        headers: { Authorization: `Bearer ${token}` }
      }
      const res = await paymentsControllerApi.payPaymentEntry(userId, opts.invoicePaymentId, opts.paymentConfirmationDto, requestOpts)
      return res.data
    } catch (e) {
      Sentry.captureException(e)
    }
  }

  @Action({ rawError: true })
  async undoPaymentEntry (invoicePaymentId: number): Promise<any> {
    const token = this.context.rootGetters['auth/jwt']
    const userId = this.context.rootGetters['user/id']
    try {
      const requestOpts = {
        headers: { Authorization: `Bearer ${token}` }
      }
      const paymentUndoDto =
      {
        isConfirmed: true,
        paymentReference: '',
        confirmationEmailToAddresses: [],
        confirmationEmailCCAddresses: []
      }
      const res = await paymentsControllerApi.undoPaymentEntry(userId, invoicePaymentId, paymentUndoDto, requestOpts)
      return res.data
    } catch (e) {
      Sentry.captureException(e)
    }
  }

  @Action({ rawError: true })
  async importInvoiceGoogleSheet (params: { importGoogleSheetDto: ImportGoogleSheetDto, dryRun: boolean }): Promise<Array<ProductSummaryDto> | undefined> {
    const token = this.context.rootGetters['auth/jwt']
    const userId = this.context.rootGetters['user/id']
    try {
      const requestOpts = {
        headers: { Authorization: `Bearer ${token}` }
      }
      return (await productControllerApi.importInvoiceGoogleSheet(userId, params.dryRun, params.importGoogleSheetDto, requestOpts)).data
    } catch (e) {
      Sentry.captureException(e)
      console.log(e)
    }
  }

  @Action({ rawError: true })
  async importInvoiceLinesGoogleSheet (params: { invoiceId: number, a2xGoogleSheetsUrl: string }): Promise<Invoice> {
    const token = this.context.rootGetters['auth/jwt']
    const userId = this.context.rootGetters['user/id']
    try {
      const requestOpts = {
        headers: { Authorization: `Bearer ${token}` }
      }
      return (await invoiceControllerApi.importInvoiceLinesFromGoogleSheet(userId, params.invoiceId, { googleSheetsUrl: params.a2xGoogleSheetsUrl }, requestOpts)).data
    } catch (e) {
      Sentry.captureException(e)
      return Promise.reject(e)
    }
  }

  @Action({ rawError: true })
  async createNewInvoice (createInvoiceFromPoOrSupplierDto: CreateInvoiceFromPoOrSupplierDto): Promise<Invoice> {
    const token = this.context.rootGetters['auth/jwt']
    const userId = this.context.rootGetters['user/id']
    try {
      const requestOpts = {
        headers: { Authorization: `Bearer ${token}` }
      }
      return (await invoiceControllerApi.createNewInvoiceFromPurchaseOrderOrSupplierForUser(userId, createInvoiceFromPoOrSupplierDto, requestOpts)).data
    } catch (e:any) {
      Sentry.captureException(e)
      await this.context.dispatch('toasts/addError', e?.response?.data?.message || 'An error occurred while trying to create invoice', { root: true })
      return Promise.reject(e)
    }
  }

  @Action({ rawError: true })
  async documentUploadForInvoice (params:{ file:File}): Promise<Document> {
    const token = this.context.rootGetters['auth/jwt']
    const userId = this.context.rootGetters['user/id']
    try {
      const types:{ [key: string]: string } = {
        documentType: DocumentDocumentTypeEnum.Invoice,
        entityType: DocumentEntityTypeEnum.Invoice
      }
      return await api.documents.fileUpload(token, userId, params.file, types)
      // const { data } = await documentControllerApi.writeGcsFileResourceFromMultiPartForm(userId, types, requestOpts)
    } catch (e:any) {
      await this.context.dispatch('toasts/addError', e?.response?.data?.message || 'An error occurred while trying to upload document', { root: true })
      Sentry.captureException(e)
      return Promise.reject(e)
    }
  }

  @Action({ rawError: true })
  async getInvoiceUploadedFiles (params:{id:number, opts: { page?: number, size?: number}}): Promise<PageDocument | null> {
    const token = this.context.rootGetters['auth/jwt']
    const userId = this.context.rootGetters['user/id']
    try {
      const requestOpts = {
        headers: { Authorization: `Bearer ${token}` }
      }
      const entityType: FetchAllDocumentsForUserByEntityIdEntityTypeEnum = FetchAllDocumentsForUserByEntityIdEntityTypeEnum.Invoice
      const entityId = params.id
      const pageable = { page: params.opts.page, size: params.opts.size }
      return (await documentControllerApi.fetchAllDocumentsForUserByEntityId(pageable, userId, entityType, entityId, requestOpts)).data
    } catch (e) {
      Sentry.captureException(e)
      return null
    }
  }

  @Action({ rawError: true })
  async documentUploadForInvoiceDocuments (params:{id:number, file:File}): Promise<Document> {
    const token = this.context.rootGetters['auth/jwt']
    const userId = this.context.rootGetters['user/id']
    try {
      const types:{ [key: string]: string } = {
        documentType: DocumentDocumentTypeEnum.Other,
        entityType: DocumentEntityTypeEnum.Invoice,
        entityId: params.id.toString()
      }
      return await api.documents.fileUpload(token, userId, params.file, types)
      // const { data } = await documentControllerApi.writeGcsFileResourceFromMultiPartForm(userId, types, requestOpts)
    } catch (e:any) {
      await this.context.dispatch('toasts/addError', e?.response?.data?.message || 'An error occurred while trying to upload document', { root: true })
      Sentry.captureException(e)
      return Promise.reject(e)
    }
  }
}
