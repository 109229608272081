import { render, staticRenderFns } from "./ToastsPopup.vue?vue&type=template&id=23f4ad22&v-if=toasts&"
import script from "./ToastsPopup.vue?vue&type=script&lang=ts&"
export * from "./ToastsPopup.vue?vue&type=script&lang=ts&"


/* normalize component */
import normalizer from "!../../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports