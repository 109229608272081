/* tslint:disable */
/* eslint-disable */
/**
 * OpenAPI definition
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import type { Configuration } from './configuration';
import type { AxiosPromise, AxiosInstance, RawAxiosRequestConfig } from 'axios';
import globalAxios from 'axios';
// Some imports not used depending on template conditions
// @ts-ignore
import { DUMMY_BASE_URL, assertParamExists, setApiKeyToObject, setBasicAuthToObject, setBearerAuthToObject, setOAuthToObject, setSearchParams, serializeDataIfNeeded, toPathString, createRequestFunction } from './common';
import type { RequestArgs } from './base';
// @ts-ignore
import { BASE_PATH, COLLECTION_FORMATS, BaseAPI, RequiredError, operationServerMap } from './base';

/**
 * 
 * @export
 * @interface AccountUserSettings
 */
export interface AccountUserSettings {
    /**
     * 
     * @type {number}
     * @memberof AccountUserSettings
     */
    'id'?: number;
    /**
     * 
     * @type {number}
     * @memberof AccountUserSettings
     */
    'userId'?: number;
    /**
     * 
     * @type {number}
     * @memberof AccountUserSettings
     */
    'accountId'?: number;
    /**
     * 
     * @type {boolean}
     * @memberof AccountUserSettings
     */
    'isFeeTrackerDailyFoundEmailEnabled'?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof AccountUserSettings
     */
    'isFeeTrackerDailyNotFoundEmailEnabled'?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof AccountUserSettings
     */
    'showFeeTrackerDailySettings'?: boolean;
}
/**
 * 
 * @export
 * @interface AmazonApiClient
 */
export interface AmazonApiClient {
    /**
     * 
     * @type {string}
     * @memberof AmazonApiClient
     */
    'applicationId'?: string;
    /**
     * 
     * @type {string}
     * @memberof AmazonApiClient
     */
    'clientId'?: string;
    /**
     * 
     * @type {string}
     * @memberof AmazonApiClient
     */
    'clientSecret'?: string;
    /**
     * 
     * @type {string}
     * @memberof AmazonApiClient
     */
    'clientRedirectUrl'?: string;
    /**
     * 
     * @type {Array<string>}
     * @memberof AmazonApiClient
     */
    'scopes'?: Array<string>;
    /**
     * 
     * @type {number}
     * @memberof AmazonApiClient
     */
    'sessionState'?: number;
}
/**
 * 
 * @export
 * @interface AmazonAuthInfoDto
 */
export interface AmazonAuthInfoDto {
    /**
     * 
     * @type {number}
     * @memberof AmazonAuthInfoDto
     */
    'id'?: number;
    /**
     * 
     * @type {string}
     * @memberof AmazonAuthInfoDto
     */
    'code'?: string;
    /**
     * 
     * @type {string}
     * @memberof AmazonAuthInfoDto
     */
    'authToken'?: string;
    /**
     * 
     * @type {string}
     * @memberof AmazonAuthInfoDto
     */
    'sellingPartnerId'?: string;
}
/**
 * 
 * @export
 * @interface AmazonOAuthToken
 */
export interface AmazonOAuthToken {
    /**
     * 
     * @type {number}
     * @memberof AmazonOAuthToken
     */
    'id'?: number;
    /**
     * 
     * @type {string}
     * @memberof AmazonOAuthToken
     */
    'amazonTenantId'?: string;
    /**
     * 
     * @type {number}
     * @memberof AmazonOAuthToken
     */
    'userId'?: number;
    /**
     * 
     * @type {number}
     * @memberof AmazonOAuthToken
     */
    'accountId'?: number;
    /**
     * 
     * @type {string}
     * @memberof AmazonOAuthToken
     */
    'sellingPartnerId'?: string;
    /**
     * 
     * @type {string}
     * @memberof AmazonOAuthToken
     */
    'token_type'?: string;
    /**
     * 
     * @type {string}
     * @memberof AmazonOAuthToken
     */
    'access_token'?: string;
    /**
     * 
     * @type {string}
     * @memberof AmazonOAuthToken
     */
    'refresh_token'?: string;
    /**
     * 
     * @type {number}
     * @memberof AmazonOAuthToken
     */
    'expires_in'?: number;
}
/**
 * 
 * @export
 * @interface AmazonProduct
 */
export interface AmazonProduct {
    /**
     * 
     * @type {number}
     * @memberof AmazonProduct
     */
    'id'?: number;
    /**
     * 
     * @type {string}
     * @memberof AmazonProduct
     */
    'name'?: string;
    /**
     * 
     * @type {string}
     * @memberof AmazonProduct
     */
    'sku'?: string;
    /**
     * 
     * @type {string}
     * @memberof AmazonProduct
     */
    'skuAlias'?: string;
    /**
     * 
     * @type {string}
     * @memberof AmazonProduct
     */
    'fnSku'?: string;
    /**
     * 
     * @type {string}
     * @memberof AmazonProduct
     */
    'asin'?: string;
    /**
     * 
     * @type {number}
     * @memberof AmazonProduct
     */
    'userId'?: number;
    /**
     * 
     * @type {number}
     * @memberof AmazonProduct
     */
    'accountId'?: number;
    /**
     * 
     * @type {number}
     * @memberof AmazonProduct
     */
    'longestSideDimension'?: number;
    /**
     * 
     * @type {string}
     * @memberof AmazonProduct
     */
    'dimensionUnit'?: AmazonProductDimensionUnitEnum;
    /**
     * 
     * @type {string}
     * @memberof AmazonProduct
     */
    'lengthAndGirthDimensionUnit'?: AmazonProductLengthAndGirthDimensionUnitEnum;
    /**
     * 
     * @type {string}
     * @memberof AmazonProduct
     */
    'longestSideDimensionUnit'?: AmazonProductLongestSideDimensionUnitEnum;
    /**
     * 
     * @type {string}
     * @memberof AmazonProduct
     */
    'medianSideDimensionUnit'?: AmazonProductMedianSideDimensionUnitEnum;
    /**
     * 
     * @type {string}
     * @memberof AmazonProduct
     */
    'shortSideDimensionUnit'?: AmazonProductShortSideDimensionUnitEnum;
    /**
     * 
     * @type {number}
     * @memberof AmazonProduct
     */
    'medianSideDimension'?: number;
    /**
     * 
     * @type {number}
     * @memberof AmazonProduct
     */
    'shortSideDimension'?: number;
    /**
     * 
     * @type {number}
     * @memberof AmazonProduct
     */
    'lengthAndGirth'?: number;
    /**
     * 
     * @type {string}
     * @memberof AmazonProduct
     */
    'amazonProductStatus'?: AmazonProductAmazonProductStatusEnum;
    /**
     * 
     * @type {string}
     * @memberof AmazonProduct
     */
    'changeDescription'?: string;
    /**
     * 
     * @type {string}
     * @memberof AmazonProduct
     */
    'session'?: string;
    /**
     * 
     * @type {number}
     * @memberof AmazonProduct
     */
    'listingPrice'?: number;
    /**
     * 
     * @type {string}
     * @memberof AmazonProduct
     */
    'fbaCondition'?: string;
    /**
     * 
     * @type {string}
     * @memberof AmazonProduct
     */
    'pricingCondition'?: string;
    /**
     * 
     * @type {number}
     * @memberof AmazonProduct
     */
    'pricingListingPrice'?: number;
    /**
     * 
     * @type {number}
     * @memberof AmazonProduct
     */
    'shippingPrice'?: number;
    /**
     * 
     * @type {number}
     * @memberof AmazonProduct
     */
    'pointsNumber'?: number;
    /**
     * 
     * @type {number}
     * @memberof AmazonProduct
     */
    'pointsValue'?: number;
    /**
     * 
     * @type {MoneyType}
     * @memberof AmazonProduct
     */
    'listingPriceObject'?: MoneyType;
    /**
     * 
     * @type {MoneyType}
     * @memberof AmazonProduct
     */
    'shippingPriceObject'?: MoneyType;
    /**
     * 
     * @type {Points}
     * @memberof AmazonProduct
     */
    'pointsObject'?: Points;
    /**
     * 
     * @type {LocalDateTime}
     * @memberof AmazonProduct
     */
    'amazonFeesEstimationTime'?: LocalDateTime;
    /**
     * 
     * @type {{ [key: string]: string; }}
     * @memberof AmazonProduct
     */
    'amazonApiMessages'?: { [key: string]: string; };
    /**
     * 
     * @type {string}
     * @memberof AmazonProduct
     */
    'productSizeTier'?: string;
    /**
     * 
     * @type {number}
     * @memberof AmazonProduct
     */
    'perUnitWeight'?: number;
    /**
     * 
     * @type {string}
     * @memberof AmazonProduct
     */
    'currency'?: string;
    /**
     * 
     * @type {string}
     * @memberof AmazonProduct
     */
    'perUnitWeightUnit'?: AmazonProductPerUnitWeightUnitEnum;
    /**
     * 
     * @type {number}
     * @memberof AmazonProduct
     */
    'landedUnitCost'?: number;
    /**
     * 
     * @type {number}
     * @memberof AmazonProduct
     */
    'costMargin'?: number;
    /**
     * 
     * @type {number}
     * @memberof AmazonProduct
     */
    'estReferralFee'?: number;
    /**
     * 
     * @type {number}
     * @memberof AmazonProduct
     */
    'estReferralFeePercentage'?: number;
    /**
     * 
     * @type {number}
     * @memberof AmazonProduct
     */
    'fbaFee'?: number;
    /**
     * 
     * @type {number}
     * @memberof AmazonProduct
     */
    'fbaFeePercentage'?: number;
    /**
     * 
     * @type {number}
     * @memberof AmazonProduct
     */
    'estFeeTotal'?: number;
    /**
     * 
     * @type {number}
     * @memberof AmazonProduct
     */
    'estFeeTotalPercentage'?: number;
    /**
     * 
     * @type {number}
     * @memberof AmazonProduct
     */
    'estVariableClosingFee'?: number;
    /**
     * 
     * @type {number}
     * @memberof AmazonProduct
     */
    'estVariableClosingFeePercentage'?: number;
    /**
     * 
     * @type {number}
     * @memberof AmazonProduct
     */
    'estOrderHandlingFeePerOrder'?: number;
    /**
     * 
     * @type {number}
     * @memberof AmazonProduct
     */
    'estOrderHandlingFeePerOrderPercentage'?: number;
    /**
     * 
     * @type {number}
     * @memberof AmazonProduct
     */
    'estPickPackFee'?: number;
    /**
     * 
     * @type {number}
     * @memberof AmazonProduct
     */
    'estPickPackFeePercentage'?: number;
    /**
     * 
     * @type {number}
     * @memberof AmazonProduct
     */
    'estWeightHandlingFee'?: number;
    /**
     * 
     * @type {number}
     * @memberof AmazonProduct
     */
    'estWeightHandlingFeePercentage'?: number;
    /**
     * 
     * @type {number}
     * @memberof AmazonProduct
     */
    'grossProfit'?: number;
    /**
     * 
     * @type {number}
     * @memberof AmazonProduct
     */
    'grossMarginPercentage'?: number;
    /**
     * 
     * @type {number}
     * @memberof AmazonProduct
     */
    'amazonFees'?: number;
}

export const AmazonProductDimensionUnitEnum = {
    Cm: 'CM',
    In: 'IN',
    Mm: 'MM'
} as const;

export type AmazonProductDimensionUnitEnum = typeof AmazonProductDimensionUnitEnum[keyof typeof AmazonProductDimensionUnitEnum];
export const AmazonProductLengthAndGirthDimensionUnitEnum = {
    Cm: 'CM',
    In: 'IN',
    Mm: 'MM'
} as const;

export type AmazonProductLengthAndGirthDimensionUnitEnum = typeof AmazonProductLengthAndGirthDimensionUnitEnum[keyof typeof AmazonProductLengthAndGirthDimensionUnitEnum];
export const AmazonProductLongestSideDimensionUnitEnum = {
    Cm: 'CM',
    In: 'IN',
    Mm: 'MM'
} as const;

export type AmazonProductLongestSideDimensionUnitEnum = typeof AmazonProductLongestSideDimensionUnitEnum[keyof typeof AmazonProductLongestSideDimensionUnitEnum];
export const AmazonProductMedianSideDimensionUnitEnum = {
    Cm: 'CM',
    In: 'IN',
    Mm: 'MM'
} as const;

export type AmazonProductMedianSideDimensionUnitEnum = typeof AmazonProductMedianSideDimensionUnitEnum[keyof typeof AmazonProductMedianSideDimensionUnitEnum];
export const AmazonProductShortSideDimensionUnitEnum = {
    Cm: 'CM',
    In: 'IN',
    Mm: 'MM'
} as const;

export type AmazonProductShortSideDimensionUnitEnum = typeof AmazonProductShortSideDimensionUnitEnum[keyof typeof AmazonProductShortSideDimensionUnitEnum];
export const AmazonProductAmazonProductStatusEnum = {
    NoChange: 'NO_CHANGE',
    NewChange: 'NEW_CHANGE',
    Accepted: 'ACCEPTED',
    OnHold: 'ON_HOLD',
    InReview: 'IN_REVIEW'
} as const;

export type AmazonProductAmazonProductStatusEnum = typeof AmazonProductAmazonProductStatusEnum[keyof typeof AmazonProductAmazonProductStatusEnum];
export const AmazonProductPerUnitWeightUnitEnum = {
    Gr: 'GR',
    Oz: 'OZ',
    Lb: 'LB',
    Kg: 'KG'
} as const;

export type AmazonProductPerUnitWeightUnitEnum = typeof AmazonProductPerUnitWeightUnitEnum[keyof typeof AmazonProductPerUnitWeightUnitEnum];

/**
 * 
 * @export
 * @interface AmazonReport
 */
export interface AmazonReport {
    /**
     * 
     * @type {number}
     * @memberof AmazonReport
     */
    'id'?: number;
    /**
     * 
     * @type {string}
     * @memberof AmazonReport
     */
    'marketPlaces'?: string;
    /**
     * 
     * @type {string}
     * @memberof AmazonReport
     */
    'amazonProcessingStatus'?: AmazonReportAmazonProcessingStatusEnum;
    /**
     * 
     * @type {string}
     * @memberof AmazonReport
     */
    'reportId'?: string;
    /**
     * 
     * @type {string}
     * @memberof AmazonReport
     */
    'reportDocumentUrl'?: string;
    /**
     * 
     * @type {string}
     * @memberof AmazonReport
     */
    'reportDocumentCsv'?: string;
    /**
     * 
     * @type {string}
     * @memberof AmazonReport
     */
    'reportType'?: string;
    /**
     * 
     * @type {string}
     * @memberof AmazonReport
     */
    'apiMessage'?: string;
    /**
     * 
     * @type {number}
     * @memberof AmazonReport
     */
    'userId'?: number;
    /**
     * 
     * @type {number}
     * @memberof AmazonReport
     */
    'accountId'?: number;
    /**
     * 
     * @type {boolean}
     * @memberof AmazonReport
     */
    'isCsvProcessed'?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof AmazonReport
     */
    'isDemoData'?: boolean;
    /**
     * 
     * @type {number}
     * @memberof AmazonReport
     */
    'numberOfCsvDownloadAttempts'?: number;
    /**
     * 
     * @type {string}
     * @memberof AmazonReport
     */
    'reportDocumentId'?: string;
    /**
     * 
     * @type {string}
     * @memberof AmazonReport
     */
    'svProcessingStatus'?: AmazonReportSvProcessingStatusEnum;
    /**
     * 
     * @type {boolean}
     * @memberof AmazonReport
     */
    'isDailyCheck'?: boolean;
    /**
     * 
     * @type {number}
     * @memberof AmazonReport
     */
    'previousReportId'?: number;
}

export const AmazonReportAmazonProcessingStatusEnum = {
    Cancelled: 'CANCELLED',
    Done: 'DONE',
    Fatal: 'FATAL',
    InProgress: 'IN_PROGRESS',
    InQueue: 'IN_QUEUE'
} as const;

export type AmazonReportAmazonProcessingStatusEnum = typeof AmazonReportAmazonProcessingStatusEnum[keyof typeof AmazonReportAmazonProcessingStatusEnum];
export const AmazonReportSvProcessingStatusEnum = {
    Queued: 'QUEUED',
    Created: 'CREATED',
    Downloaded: 'DOWNLOADED',
    Processed: 'PROCESSED',
    Completed: 'COMPLETED',
    Error: 'ERROR',
    Expired: 'EXPIRED'
} as const;

export type AmazonReportSvProcessingStatusEnum = typeof AmazonReportSvProcessingStatusEnum[keyof typeof AmazonReportSvProcessingStatusEnum];

/**
 * 
 * @export
 * @interface AmazonSyncStatusDto
 */
export interface AmazonSyncStatusDto {
    /**
     * 
     * @type {boolean}
     * @memberof AmazonSyncStatusDto
     */
    'isCsvProcessed'?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof AmazonSyncStatusDto
     */
    'isDemoData'?: boolean;
    /**
     * 
     * @type {string}
     * @memberof AmazonSyncStatusDto
     */
    'reportType'?: string;
    /**
     * 
     * @type {string}
     * @memberof AmazonSyncStatusDto
     */
    'createdAt'?: string;
    /**
     * 
     * @type {string}
     * @memberof AmazonSyncStatusDto
     */
    'updatedAt'?: string;
    /**
     * 
     * @type {string}
     * @memberof AmazonSyncStatusDto
     */
    'amazonErrorMessage'?: string;
    /**
     * 
     * @type {string}
     * @memberof AmazonSyncStatusDto
     */
    'svProcessingStatus'?: AmazonSyncStatusDtoSvProcessingStatusEnum;
}

export const AmazonSyncStatusDtoSvProcessingStatusEnum = {
    Queued: 'QUEUED',
    Created: 'CREATED',
    Downloaded: 'DOWNLOADED',
    Processed: 'PROCESSED',
    Completed: 'COMPLETED',
    Error: 'ERROR',
    Expired: 'EXPIRED'
} as const;

export type AmazonSyncStatusDtoSvProcessingStatusEnum = typeof AmazonSyncStatusDtoSvProcessingStatusEnum[keyof typeof AmazonSyncStatusDtoSvProcessingStatusEnum];

/**
 * 
 * @export
 * @interface Chronology
 */
export interface Chronology {
    /**
     * 
     * @type {string}
     * @memberof Chronology
     */
    'id'?: string;
    /**
     * 
     * @type {string}
     * @memberof Chronology
     */
    'calendarType'?: string;
}
/**
 * 
 * @export
 * @interface LocalDateTime
 */
export interface LocalDateTime {
    /**
     * 
     * @type {number}
     * @memberof LocalDateTime
     */
    'nano'?: number;
    /**
     * 
     * @type {number}
     * @memberof LocalDateTime
     */
    'year'?: number;
    /**
     * 
     * @type {number}
     * @memberof LocalDateTime
     */
    'monthValue'?: number;
    /**
     * 
     * @type {number}
     * @memberof LocalDateTime
     */
    'dayOfMonth'?: number;
    /**
     * 
     * @type {number}
     * @memberof LocalDateTime
     */
    'hour'?: number;
    /**
     * 
     * @type {number}
     * @memberof LocalDateTime
     */
    'minute'?: number;
    /**
     * 
     * @type {number}
     * @memberof LocalDateTime
     */
    'second'?: number;
    /**
     * 
     * @type {string}
     * @memberof LocalDateTime
     */
    'dayOfWeek'?: LocalDateTimeDayOfWeekEnum;
    /**
     * 
     * @type {number}
     * @memberof LocalDateTime
     */
    'dayOfYear'?: number;
    /**
     * 
     * @type {string}
     * @memberof LocalDateTime
     */
    'month'?: LocalDateTimeMonthEnum;
    /**
     * 
     * @type {Chronology}
     * @memberof LocalDateTime
     */
    'chronology'?: Chronology;
}

export const LocalDateTimeDayOfWeekEnum = {
    Monday: 'MONDAY',
    Tuesday: 'TUESDAY',
    Wednesday: 'WEDNESDAY',
    Thursday: 'THURSDAY',
    Friday: 'FRIDAY',
    Saturday: 'SATURDAY',
    Sunday: 'SUNDAY'
} as const;

export type LocalDateTimeDayOfWeekEnum = typeof LocalDateTimeDayOfWeekEnum[keyof typeof LocalDateTimeDayOfWeekEnum];
export const LocalDateTimeMonthEnum = {
    January: 'JANUARY',
    February: 'FEBRUARY',
    March: 'MARCH',
    April: 'APRIL',
    May: 'MAY',
    June: 'JUNE',
    July: 'JULY',
    August: 'AUGUST',
    September: 'SEPTEMBER',
    October: 'OCTOBER',
    November: 'NOVEMBER',
    December: 'DECEMBER'
} as const;

export type LocalDateTimeMonthEnum = typeof LocalDateTimeMonthEnum[keyof typeof LocalDateTimeMonthEnum];

/**
 * 
 * @export
 * @interface Marketplace
 */
export interface Marketplace {
    /**
     * 
     * @type {string}
     * @memberof Marketplace
     */
    'id'?: string;
    /**
     * 
     * @type {string}
     * @memberof Marketplace
     */
    'name'?: string;
    /**
     * 
     * @type {string}
     * @memberof Marketplace
     */
    'countryCode'?: string;
    /**
     * 
     * @type {string}
     * @memberof Marketplace
     */
    'defaultCurrencyCode'?: string;
    /**
     * 
     * @type {string}
     * @memberof Marketplace
     */
    'defaultLanguageCode'?: string;
    /**
     * 
     * @type {string}
     * @memberof Marketplace
     */
    'domainName'?: string;
}
/**
 * 
 * @export
 * @interface MarketplaceParticipation
 */
export interface MarketplaceParticipation {
    /**
     * 
     * @type {Marketplace}
     * @memberof MarketplaceParticipation
     */
    'marketplace'?: Marketplace;
    /**
     * 
     * @type {Participation}
     * @memberof MarketplaceParticipation
     */
    'participation'?: Participation;
}
/**
 * 
 * @export
 * @interface MoneyType
 */
export interface MoneyType {
    /**
     * 
     * @type {string}
     * @memberof MoneyType
     */
    'currencyCode'?: string;
    /**
     * 
     * @type {number}
     * @memberof MoneyType
     */
    'amount'?: number;
}
/**
 * 
 * @export
 * @interface NotificationDto
 */
export interface NotificationDto {
    /**
     * 
     * @type {number}
     * @memberof NotificationDto
     */
    'id'?: number;
    /**
     * 
     * @type {number}
     * @memberof NotificationDto
     */
    'userId'?: number;
    /**
     * 
     * @type {number}
     * @memberof NotificationDto
     */
    'accountId'?: number;
    /**
     * 
     * @type {string}
     * @memberof NotificationDto
     */
    'notificationStatus'?: NotificationDtoNotificationStatusEnum;
    /**
     * 
     * @type {string}
     * @memberof NotificationDto
     */
    'summary'?: string;
    /**
     * 
     * @type {string}
     * @memberof NotificationDto
     */
    'message'?: string;
    /**
     * 
     * @type {string}
     * @memberof NotificationDto
     */
    'notificationType'?: NotificationDtoNotificationTypeEnum;
    /**
     * 
     * @type {string}
     * @memberof NotificationDto
     */
    'notificationSource'?: NotificationDtoNotificationSourceEnum;
    /**
     * 
     * @type {string}
     * @memberof NotificationDto
     */
    'entityType'?: string;
    /**
     * 
     * @type {string}
     * @memberof NotificationDto
     */
    'entityId'?: string;
    /**
     * 
     * @type {string}
     * @memberof NotificationDto
     */
    'messageDateTime'?: string;
}

export const NotificationDtoNotificationStatusEnum = {
    Unread: 'UNREAD',
    Read: 'READ',
    Dismissed: 'DISMISSED'
} as const;

export type NotificationDtoNotificationStatusEnum = typeof NotificationDtoNotificationStatusEnum[keyof typeof NotificationDtoNotificationStatusEnum];
export const NotificationDtoNotificationTypeEnum = {
    Info: 'INFO',
    ActionNeeded: 'ACTION_NEEDED',
    Error: 'ERROR'
} as const;

export type NotificationDtoNotificationTypeEnum = typeof NotificationDtoNotificationTypeEnum[keyof typeof NotificationDtoNotificationTypeEnum];
export const NotificationDtoNotificationSourceEnum = {
    GatewayService: 'GATEWAY_SERVICE',
    InvoiceService: 'INVOICE_SERVICE',
    NotificationService: 'NOTIFICATION_SERVICE',
    ProductService: 'PRODUCT_SERVICE',
    ReportingService: 'REPORTING_SERVICE',
    SchedulerService: 'SCHEDULER_SERVICE',
    SupplierService: 'SUPPLIER_SERVICE',
    ShopifyService: 'SHOPIFY_SERVICE',
    AmazonService: 'AMAZON_SERVICE',
    UserService: 'USER_SERVICE',
    XeroService: 'XERO_SERVICE',
    OrdersService: 'ORDERS_SERVICE',
    Sv1Service: 'SV1_SERVICE',
    PurchasingService: 'PURCHASING_SERVICE'
} as const;

export type NotificationDtoNotificationSourceEnum = typeof NotificationDtoNotificationSourceEnum[keyof typeof NotificationDtoNotificationSourceEnum];

/**
 * 
 * @export
 * @interface Participation
 */
export interface Participation {
    /**
     * 
     * @type {boolean}
     * @memberof Participation
     */
    'isParticipating'?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof Participation
     */
    'hasSuspendedListings'?: boolean;
}
/**
 * 
 * @export
 * @interface Points
 */
export interface Points {
    /**
     * 
     * @type {number}
     * @memberof Points
     */
    'pointsNumber'?: number;
    /**
     * 
     * @type {MoneyType}
     * @memberof Points
     */
    'pointsMonetaryValue'?: MoneyType;
}

/**
 * AmazonAdminControllerApi - axios parameter creator
 * @export
 */
export const AmazonAdminControllerApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @param {number} accountId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        resendAmazonProductsToProductServiceForDailyCheck: async (accountId: number, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'accountId' is not null or undefined
            assertParamExists('resendAmazonProductsToProductServiceForDailyCheck', 'accountId', accountId)
            const localVarPath = `/admin/amazon/resend-daily-check/{accountId}`
                .replace(`{${"accountId"}}`, encodeURIComponent(String(accountId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        runDailyEmails: async (options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/admin/amazon/run-daily-emails`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        runDailyProductCheck: async (options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/admin/amazon/run-daily-check`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} [body] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        sendTestEmail: async (body?: string, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/admin/amazon/test-email`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(body, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {Array<string>} [requestBody] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateAmazonProducts: async (requestBody?: Array<string>, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/admin/amazon/create-demo-auth`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(requestBody, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateFeeTrackerDailySettings: async (options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/admin/amazon/update-fee-tracker-daily-settings`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * AmazonAdminControllerApi - functional programming interface
 * @export
 */
export const AmazonAdminControllerApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = AmazonAdminControllerApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @param {number} accountId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async resendAmazonProductsToProductServiceForDailyCheck(accountId: number, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<AmazonProduct>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.resendAmazonProductsToProductServiceForDailyCheck(accountId, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['AmazonAdminControllerApi.resendAmazonProductsToProductServiceForDailyCheck']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async runDailyEmails(options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<{ [key: string]: string; }>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.runDailyEmails(options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['AmazonAdminControllerApi.runDailyEmails']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async runDailyProductCheck(options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<AmazonSyncStatusDto>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.runDailyProductCheck(options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['AmazonAdminControllerApi.runDailyProductCheck']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @param {string} [body] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async sendTestEmail(body?: string, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<string>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.sendTestEmail(body, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['AmazonAdminControllerApi.sendTestEmail']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @param {Array<string>} [requestBody] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async updateAmazonProducts(requestBody?: Array<string>, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<AmazonOAuthToken>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.updateAmazonProducts(requestBody, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['AmazonAdminControllerApi.updateAmazonProducts']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async updateFeeTrackerDailySettings(options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<{ [key: string]: AccountUserSettings; }>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.updateFeeTrackerDailySettings(options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['AmazonAdminControllerApi.updateFeeTrackerDailySettings']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
    }
};

/**
 * AmazonAdminControllerApi - factory interface
 * @export
 */
export const AmazonAdminControllerApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = AmazonAdminControllerApiFp(configuration)
    return {
        /**
         * 
         * @param {number} accountId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        resendAmazonProductsToProductServiceForDailyCheck(accountId: number, options?: any): AxiosPromise<Array<AmazonProduct>> {
            return localVarFp.resendAmazonProductsToProductServiceForDailyCheck(accountId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        runDailyEmails(options?: any): AxiosPromise<{ [key: string]: string; }> {
            return localVarFp.runDailyEmails(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        runDailyProductCheck(options?: any): AxiosPromise<Array<AmazonSyncStatusDto>> {
            return localVarFp.runDailyProductCheck(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} [body] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        sendTestEmail(body?: string, options?: any): AxiosPromise<string> {
            return localVarFp.sendTestEmail(body, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {Array<string>} [requestBody] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateAmazonProducts(requestBody?: Array<string>, options?: any): AxiosPromise<Array<AmazonOAuthToken>> {
            return localVarFp.updateAmazonProducts(requestBody, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateFeeTrackerDailySettings(options?: any): AxiosPromise<{ [key: string]: AccountUserSettings; }> {
            return localVarFp.updateFeeTrackerDailySettings(options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * AmazonAdminControllerApi - object-oriented interface
 * @export
 * @class AmazonAdminControllerApi
 * @extends {BaseAPI}
 */
export class AmazonAdminControllerApi extends BaseAPI {
    /**
     * 
     * @param {number} accountId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AmazonAdminControllerApi
     */
    public resendAmazonProductsToProductServiceForDailyCheck(accountId: number, options?: RawAxiosRequestConfig) {
        return AmazonAdminControllerApiFp(this.configuration).resendAmazonProductsToProductServiceForDailyCheck(accountId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AmazonAdminControllerApi
     */
    public runDailyEmails(options?: RawAxiosRequestConfig) {
        return AmazonAdminControllerApiFp(this.configuration).runDailyEmails(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AmazonAdminControllerApi
     */
    public runDailyProductCheck(options?: RawAxiosRequestConfig) {
        return AmazonAdminControllerApiFp(this.configuration).runDailyProductCheck(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} [body] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AmazonAdminControllerApi
     */
    public sendTestEmail(body?: string, options?: RawAxiosRequestConfig) {
        return AmazonAdminControllerApiFp(this.configuration).sendTestEmail(body, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {Array<string>} [requestBody] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AmazonAdminControllerApi
     */
    public updateAmazonProducts(requestBody?: Array<string>, options?: RawAxiosRequestConfig) {
        return AmazonAdminControllerApiFp(this.configuration).updateAmazonProducts(requestBody, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AmazonAdminControllerApi
     */
    public updateFeeTrackerDailySettings(options?: RawAxiosRequestConfig) {
        return AmazonAdminControllerApiFp(this.configuration).updateFeeTrackerDailySettings(options).then((request) => request(this.axios, this.basePath));
    }
}



/**
 * AmazonAuthControllerApi - axios parameter creator
 * @export
 */
export const AmazonAuthControllerApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @param {number} userId 
         * @param {AmazonAuthInfoDto} [amazonAuthInfoDto] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createNewOauthToken: async (userId: number, amazonAuthInfoDto?: AmazonAuthInfoDto, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'userId' is not null or undefined
            assertParamExists('createNewOauthToken', 'userId', userId)
            const localVarPath = `/amazon/{userId}/oauth/generate`
                .replace(`{${"userId"}}`, encodeURIComponent(String(userId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(amazonAuthInfoDto, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} userId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteOauthTokenByUserId: async (userId: number, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'userId' is not null or undefined
            assertParamExists('deleteOauthTokenByUserId', 'userId', userId)
            const localVarPath = `/amazon/{userId}/oauth`
                .replace(`{${"userId"}}`, encodeURIComponent(String(userId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} userId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getApiClientByUserId: async (userId: number, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'userId' is not null or undefined
            assertParamExists('getApiClientByUserId', 'userId', userId)
            const localVarPath = `/amazon/{userId}/api-client`
                .replace(`{${"userId"}}`, encodeURIComponent(String(userId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} userId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getOauthTokenByUserId: async (userId: number, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'userId' is not null or undefined
            assertParamExists('getOauthTokenByUserId', 'userId', userId)
            const localVarPath = `/amazon/{userId}/oauth`
                .replace(`{${"userId"}}`, encodeURIComponent(String(userId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * AmazonAuthControllerApi - functional programming interface
 * @export
 */
export const AmazonAuthControllerApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = AmazonAuthControllerApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @param {number} userId 
         * @param {AmazonAuthInfoDto} [amazonAuthInfoDto] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async createNewOauthToken(userId: number, amazonAuthInfoDto?: AmazonAuthInfoDto, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<AmazonOAuthToken>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.createNewOauthToken(userId, amazonAuthInfoDto, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['AmazonAuthControllerApi.createNewOauthToken']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @param {number} userId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async deleteOauthTokenByUserId(userId: number, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<{ [key: string]: string; }>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.deleteOauthTokenByUserId(userId, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['AmazonAuthControllerApi.deleteOauthTokenByUserId']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @param {number} userId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getApiClientByUserId(userId: number, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<AmazonApiClient>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getApiClientByUserId(userId, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['AmazonAuthControllerApi.getApiClientByUserId']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @param {number} userId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getOauthTokenByUserId(userId: number, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<AmazonOAuthToken>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getOauthTokenByUserId(userId, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['AmazonAuthControllerApi.getOauthTokenByUserId']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
    }
};

/**
 * AmazonAuthControllerApi - factory interface
 * @export
 */
export const AmazonAuthControllerApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = AmazonAuthControllerApiFp(configuration)
    return {
        /**
         * 
         * @param {number} userId 
         * @param {AmazonAuthInfoDto} [amazonAuthInfoDto] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createNewOauthToken(userId: number, amazonAuthInfoDto?: AmazonAuthInfoDto, options?: any): AxiosPromise<AmazonOAuthToken> {
            return localVarFp.createNewOauthToken(userId, amazonAuthInfoDto, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {number} userId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteOauthTokenByUserId(userId: number, options?: any): AxiosPromise<{ [key: string]: string; }> {
            return localVarFp.deleteOauthTokenByUserId(userId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {number} userId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getApiClientByUserId(userId: number, options?: any): AxiosPromise<AmazonApiClient> {
            return localVarFp.getApiClientByUserId(userId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {number} userId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getOauthTokenByUserId(userId: number, options?: any): AxiosPromise<Array<AmazonOAuthToken>> {
            return localVarFp.getOauthTokenByUserId(userId, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * AmazonAuthControllerApi - object-oriented interface
 * @export
 * @class AmazonAuthControllerApi
 * @extends {BaseAPI}
 */
export class AmazonAuthControllerApi extends BaseAPI {
    /**
     * 
     * @param {number} userId 
     * @param {AmazonAuthInfoDto} [amazonAuthInfoDto] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AmazonAuthControllerApi
     */
    public createNewOauthToken(userId: number, amazonAuthInfoDto?: AmazonAuthInfoDto, options?: RawAxiosRequestConfig) {
        return AmazonAuthControllerApiFp(this.configuration).createNewOauthToken(userId, amazonAuthInfoDto, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {number} userId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AmazonAuthControllerApi
     */
    public deleteOauthTokenByUserId(userId: number, options?: RawAxiosRequestConfig) {
        return AmazonAuthControllerApiFp(this.configuration).deleteOauthTokenByUserId(userId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {number} userId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AmazonAuthControllerApi
     */
    public getApiClientByUserId(userId: number, options?: RawAxiosRequestConfig) {
        return AmazonAuthControllerApiFp(this.configuration).getApiClientByUserId(userId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {number} userId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AmazonAuthControllerApi
     */
    public getOauthTokenByUserId(userId: number, options?: RawAxiosRequestConfig) {
        return AmazonAuthControllerApiFp(this.configuration).getOauthTokenByUserId(userId, options).then((request) => request(this.axios, this.basePath));
    }
}



/**
 * AmazonControllerApi - axios parameter creator
 * @export
 */
export const AmazonControllerApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @param {number} userId 
         * @param {NotificationDto} [notificationDto] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        sendNotification: async (userId: number, notificationDto?: NotificationDto, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'userId' is not null or undefined
            assertParamExists('sendNotification', 'userId', userId)
            const localVarPath = `/amazon/{userId}/notification`
                .replace(`{${"userId"}}`, encodeURIComponent(String(userId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(notificationDto, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * AmazonControllerApi - functional programming interface
 * @export
 */
export const AmazonControllerApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = AmazonControllerApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @param {number} userId 
         * @param {NotificationDto} [notificationDto] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async sendNotification(userId: number, notificationDto?: NotificationDto, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<NotificationDto>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.sendNotification(userId, notificationDto, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['AmazonControllerApi.sendNotification']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
    }
};

/**
 * AmazonControllerApi - factory interface
 * @export
 */
export const AmazonControllerApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = AmazonControllerApiFp(configuration)
    return {
        /**
         * 
         * @param {number} userId 
         * @param {NotificationDto} [notificationDto] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        sendNotification(userId: number, notificationDto?: NotificationDto, options?: any): AxiosPromise<NotificationDto> {
            return localVarFp.sendNotification(userId, notificationDto, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * AmazonControllerApi - object-oriented interface
 * @export
 * @class AmazonControllerApi
 * @extends {BaseAPI}
 */
export class AmazonControllerApi extends BaseAPI {
    /**
     * 
     * @param {number} userId 
     * @param {NotificationDto} [notificationDto] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AmazonControllerApi
     */
    public sendNotification(userId: number, notificationDto?: NotificationDto, options?: RawAxiosRequestConfig) {
        return AmazonControllerApiFp(this.configuration).sendNotification(userId, notificationDto, options).then((request) => request(this.axios, this.basePath));
    }
}



/**
 * AmazonSyncControllerApi - axios parameter creator
 * @export
 */
export const AmazonSyncControllerApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @param {number} userId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        checkAmazonProducts: async (userId: number, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'userId' is not null or undefined
            assertParamExists('checkAmazonProducts', 'userId', userId)
            const localVarPath = `/amazon/{userId}/sync/products/daily-check`
                .replace(`{${"userId"}}`, encodeURIComponent(String(userId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} userId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getMarketPlaceCurrencyDetails: async (userId: number, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'userId' is not null or undefined
            assertParamExists('getMarketPlaceCurrencyDetails', 'userId', userId)
            const localVarPath = `/amazon/{userId}/marketPlaceDetails`
                .replace(`{${"userId"}}`, encodeURIComponent(String(userId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} userId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getSyncProducts: async (userId: number, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'userId' is not null or undefined
            assertParamExists('getSyncProducts', 'userId', userId)
            const localVarPath = `/amazon/{userId}/sync/products`
                .replace(`{${"userId"}}`, encodeURIComponent(String(userId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} userId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getSyncProductsStatus: async (userId: number, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'userId' is not null or undefined
            assertParamExists('getSyncProductsStatus', 'userId', userId)
            const localVarPath = `/amazon/{userId}/sync/products/status`
                .replace(`{${"userId"}}`, encodeURIComponent(String(userId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} userId 
         * @param {string} [body] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        sendEmailToUser: async (userId: number, body?: string, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'userId' is not null or undefined
            assertParamExists('sendEmailToUser', 'userId', userId)
            const localVarPath = `/amazon/{userId}/email`
                .replace(`{${"userId"}}`, encodeURIComponent(String(userId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(body, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} userId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        testSync: async (userId: number, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'userId' is not null or undefined
            assertParamExists('testSync', 'userId', userId)
            const localVarPath = `/amazon/{userId}/sync/process`
                .replace(`{${"userId"}}`, encodeURIComponent(String(userId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * AmazonSyncControllerApi - functional programming interface
 * @export
 */
export const AmazonSyncControllerApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = AmazonSyncControllerApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @param {number} userId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async checkAmazonProducts(userId: number, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<AmazonSyncStatusDto>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.checkAmazonProducts(userId, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['AmazonSyncControllerApi.checkAmazonProducts']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @param {number} userId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getMarketPlaceCurrencyDetails(userId: number, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<MarketplaceParticipation>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getMarketPlaceCurrencyDetails(userId, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['AmazonSyncControllerApi.getMarketPlaceCurrencyDetails']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @param {number} userId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getSyncProducts(userId: number, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<AmazonSyncStatusDto>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getSyncProducts(userId, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['AmazonSyncControllerApi.getSyncProducts']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @param {number} userId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getSyncProductsStatus(userId: number, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<AmazonSyncStatusDto>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getSyncProductsStatus(userId, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['AmazonSyncControllerApi.getSyncProductsStatus']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @param {number} userId 
         * @param {string} [body] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async sendEmailToUser(userId: number, body?: string, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<{ [key: string]: string; }>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.sendEmailToUser(userId, body, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['AmazonSyncControllerApi.sendEmailToUser']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @param {number} userId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async testSync(userId: number, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<AmazonReport>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.testSync(userId, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['AmazonSyncControllerApi.testSync']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
    }
};

/**
 * AmazonSyncControllerApi - factory interface
 * @export
 */
export const AmazonSyncControllerApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = AmazonSyncControllerApiFp(configuration)
    return {
        /**
         * 
         * @param {number} userId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        checkAmazonProducts(userId: number, options?: any): AxiosPromise<AmazonSyncStatusDto> {
            return localVarFp.checkAmazonProducts(userId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {number} userId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getMarketPlaceCurrencyDetails(userId: number, options?: any): AxiosPromise<MarketplaceParticipation> {
            return localVarFp.getMarketPlaceCurrencyDetails(userId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {number} userId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getSyncProducts(userId: number, options?: any): AxiosPromise<AmazonSyncStatusDto> {
            return localVarFp.getSyncProducts(userId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {number} userId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getSyncProductsStatus(userId: number, options?: any): AxiosPromise<AmazonSyncStatusDto> {
            return localVarFp.getSyncProductsStatus(userId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {number} userId 
         * @param {string} [body] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        sendEmailToUser(userId: number, body?: string, options?: any): AxiosPromise<{ [key: string]: string; }> {
            return localVarFp.sendEmailToUser(userId, body, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {number} userId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        testSync(userId: number, options?: any): AxiosPromise<Array<AmazonReport>> {
            return localVarFp.testSync(userId, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * AmazonSyncControllerApi - object-oriented interface
 * @export
 * @class AmazonSyncControllerApi
 * @extends {BaseAPI}
 */
export class AmazonSyncControllerApi extends BaseAPI {
    /**
     * 
     * @param {number} userId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AmazonSyncControllerApi
     */
    public checkAmazonProducts(userId: number, options?: RawAxiosRequestConfig) {
        return AmazonSyncControllerApiFp(this.configuration).checkAmazonProducts(userId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {number} userId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AmazonSyncControllerApi
     */
    public getMarketPlaceCurrencyDetails(userId: number, options?: RawAxiosRequestConfig) {
        return AmazonSyncControllerApiFp(this.configuration).getMarketPlaceCurrencyDetails(userId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {number} userId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AmazonSyncControllerApi
     */
    public getSyncProducts(userId: number, options?: RawAxiosRequestConfig) {
        return AmazonSyncControllerApiFp(this.configuration).getSyncProducts(userId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {number} userId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AmazonSyncControllerApi
     */
    public getSyncProductsStatus(userId: number, options?: RawAxiosRequestConfig) {
        return AmazonSyncControllerApiFp(this.configuration).getSyncProductsStatus(userId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {number} userId 
     * @param {string} [body] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AmazonSyncControllerApi
     */
    public sendEmailToUser(userId: number, body?: string, options?: RawAxiosRequestConfig) {
        return AmazonSyncControllerApiFp(this.configuration).sendEmailToUser(userId, body, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {number} userId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AmazonSyncControllerApi
     */
    public testSync(userId: number, options?: RawAxiosRequestConfig) {
        return AmazonSyncControllerApiFp(this.configuration).testSync(userId, options).then((request) => request(this.axios, this.basePath));
    }
}



