import type { RouteConfig } from 'vue-router'

import { ensureToken } from '@/router/guards/ensureToken'

export default [
  {
    path: '/fee-tracker-pro',
    name: 'feeTrackerPro',
    meta: {
      isFeeTrackerActive: true
    },
    component: () => import(/* webpackChunkName: "fee-tracker-pro-views" */ './views/FeeTrackerPro.vue'),
    beforeEnter: ensureToken
  },
  {
    path: '/marketplace-overview',
    name: 'marketplaceOverview',
    component: () => import(/* webpackChunkName: "overview-views" */ './views/MarketplaceOverview.vue'),
    meta: {
      isFeeTrackerActive: true,
      sv1: true
    },
    beforeEnter: ensureToken
  },
  {
    path: '/service-fees',
    name: 'ServiceFees',
    component: () => import(/* webpackChunkName: "service-fees-views" */ './views/ServiceFees.vue'),
    meta: {
      isFeeTrackerActive: true,
      sv1: true
    },
    beforeEnter: ensureToken
  },
  {
    path: '/product-sales',
    name: 'ProductSales',
    component: () => import(/* webpackChunkName: "product-sales-views" */ './views/ProductSales.vue'),
    meta: {
      isFeeTrackerActive: true,
      sv1: true
    },
    beforeEnter: ensureToken
  },
  {
    path: '/product-profitability',
    name: 'ProductProfitability',
    component: () => import(/* webpackChunkName: "product-profitability-views" */ './views/ProductProfitability.vue'),
    meta: {
      isFeeTrackerActive: true,
      sv1: true
    },
    beforeEnter: ensureToken
  },
  {
    path: '/product-order-metrics',
    name: 'ProductOrderMetrics',
    component: () => import(/* webpackChunkName: "product-order-metrics-views" */ './views/ProductOrderMetrics.vue'),
    meta: {
      isFeeTrackerActive: true,
      sv1: true
    },
    beforeEnter: ensureToken
  }
] as Array<RouteConfig>
