
import { Component, Prop, Vue } from 'vue-property-decorator'
import { Action, Mutation, State } from 'vuex-class'

import type { NotificationUpdateStatusDto } from '@/client/notifications'
import { NotificationUpdateStatusDtoNotificationStatusEnum } from '@/client/notifications'
import { dateTimeDifference } from '@/utils/date'

import { INotification } from '../types'
import NotificationViewerDialog from './NotificationViewerDialog.vue'

const namespaces = {
  app: 'app',
  auth: 'auth',
  user: 'user',
  toasts: 'toasts',
  notifications: 'notifications'
}

@Component({
  components: {
    NotificationViewerDialog
  }
})
export default class Notifications extends Vue {
  @Action('updateNotifications', { namespace: namespaces.notifications })
    updateNotifications!: (opts: {
    notificationId: number
    notificationUpdateStatus?: NotificationUpdateStatusDto
  }) => Promise<any>

  // @Action('getPageNotificationsQueue', { namespace: namespaces.notifications })
  // getPageNotificationsQueue!: (pageable: Pageable) => Promise<void>;

  @State('notificationsQueue', { namespace: namespaces.notifications })
    notificationsQueue?: INotification[]

  @Mutation('setNotifications', { namespace: namespaces.notifications })
    setNotifications!: (notifications: INotification[]) => void

  @Prop({ default: true })
  readonly loading!: boolean

  open = false
  dateTimeDifference = dateTimeDifference
  notificationsViewerOpen = false
  selectedNotification?: INotification = {}

  async updateNotificationStatus (
    notificationId: number,
    notificationStatus: NotificationUpdateStatusDtoNotificationStatusEnum
  ) {
    const notificationUpdateStatus = {
      id: notificationId,
      notificationStatus
    }
    await this.updateNotifications({
      notificationId,
      notificationUpdateStatus
    })
    const notifications = this.notificationsQueue?.filter((n) => {
      return n.id !== notificationId
    })
    this.setNotifications(notifications as INotification[])
  }

  get notificationList () {
    return this.notificationsQueue?.slice(0, 10)
  }

  dateFormatter (date: string) {
    return dateTimeDifference(date)
  }

  getStatusColor (notificationStatus: string) {
    return notificationStatus === 'UNREAD' ? 'text--primary' : '' || ''
  }

  getStatusType (status: string) {
    if (status === 'ERROR') return 'error'
    else if (status === 'INFO') return 'info'
    else if (status === 'ACTION_NEEDED') return 'warning'
  }

  get notificationsCount () {
    if (this.notificationsQueue?.length) {
      return this.notificationsQueue.filter(
        (x) => x?.notificationStatus === 'UNREAD'
      ).length
    } else return 0
  }

  async openNotification (notification: INotification) {
    this.selectedNotification = notification
    this.notificationsViewerOpen = true
    const notificationUpdateStatus = {
      id: this.selectedNotification.id,
      notificationStatus:
        NotificationUpdateStatusDtoNotificationStatusEnum.Read
    }
    await this.updateNotifications({
      notificationId: this.selectedNotification.id as number,
      notificationUpdateStatus
    })
  }

  navigate () {
    this.open = false
    this.$router
      .push({
        name: 'notifications'
      })
      .catch(() => console.log(''))
  }
}
