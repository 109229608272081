
import { Component, Prop, Vue } from 'vue-property-decorator'

import type { IToast } from '@/modules/toasts/types'
import type { ObjectKeyAsAny } from '@/types/app'

@Component
export default class ErrorToast extends Vue {
  @Prop() readonly toast!: IToast
  @Prop() readonly show!: ObjectKeyAsAny
  @Prop() readonly timeout!: number
  @Prop() readonly multiLine!: boolean
}
