
import { Component, Prop, Vue } from 'vue-property-decorator'
import { Getter } from 'vuex-class'

const namespaces = {
  user: 'user'
}

@Component
export default class AppCreateNewDialog extends Vue {
   @Prop()
     show!: boolean

  @Getter('isAdmin', { namespace: namespaces.user }) isAdmin!: boolean

  get shouldShow () {
    return this.show
  }

  set shouldShow (val: boolean) {
    this.$emit('set-show', val)
  }

  handleRouteChange (name: string) {
    this.$emit('set-show', false)
    this.$router.push({
      name
    })
  }

  get items () {
    return [
      {
        title: 'Contacts',
        name: 'addSupplier',
        icon: 'mdi-card-account-mail'
      },
      {
        title: 'Products',
        name: 'addProduct',
        icon: 'mdi-tag-multiple'
      },
      {
        title: 'Invoices',
        name: 'addInvoice',
        icon: 'mdi-receipt'
      },
      this.isAdmin
        ? {
            title: 'Users',
            name: 'users',
            icon: 'mdi-account-multiple'
          }
        : null,
      this.isAdmin
        ? {
            title: 'Integrations',
            name: 'integrations',
            icon: 'mdi-power-plug'
          }
        : null
    ].filter(Boolean)
  }
}
