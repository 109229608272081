import task from 'ency'
import Vue, { ComponentOptions } from 'vue'
import type { DefaultData } from 'vue/types/options'
import { createDecorator } from 'vue-class-component'

export const Task = (taskKey: string) =>
  createDecorator(
    (options: ComponentOptions<Vue, DefaultData<Vue>>, key: string) => {
      // Keep the original method for later.
      if (!options.methods) { throw new Error('No methods found to apply task to') }
      const originalMethod = options.methods[key]
      if (!originalMethod) { throw new Error(`Could not find method ${key} to apply task to`) }

      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore
      const wrappedFunction = task(function * (that: any, ...args: any[]) {
        yield originalMethod.apply(that, args)
      })
      if (!options.computed) options.computed = {}

      options.computed[taskKey] = function () {
        const taskWrapper = wrappedFunction.onError(
          ({ error }: { error: any }) => {
            throw error
          }
        )
        return Vue.observable(taskWrapper)
      }

      // Wrap the method with the logging logic.
      options.methods[key] = function (...args: any[]) {
        return wrappedFunction.run(this, ...args)
      }
    }
  )
