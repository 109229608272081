
import { isEmpty } from 'lodash'
import { Component, Prop, Vue } from 'vue-property-decorator'

import type { Notification } from '@/client/notifications'
import { dateTimeDifference } from '@/utils/date'

@Component
export default class NotificationViewerDialog extends Vue {
  @Prop({ default: false })
    open!: boolean

  @Prop({ required: true })
    notification!: Notification

  isEmpty = isEmpty
  dateTimeDifference = dateTimeDifference

  get dialogOpen () {
    return this.open
  }

  set dialogOpen (val: boolean) {
    this.$emit('setDialogOpen', val)
  }

  dateFormatter (date: string) {
    return dateTimeDifference(date)
  }

  getStatusColor (status: string) {
    if (status === 'ERROR') return 'error'
    else if (status === 'INFO') return 'blue'
    else if (status === 'ACTION_NEEDED') return 'orange'
  }
}
