var render = function render(){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return (_vm.user)?_c('v-menu',{attrs:{"close-on-content-click":false,"bottom":"","left":"","offset-y":"","origin":"top right","transition":"slide-y-transition","max-width":"280px"},scopedSlots:_vm._u([{key:"activator",fn:function({ on: onMenu }){return [_c('v-btn',_vm._g({attrs:{"text":""}},{ ...onMenu }),[_c('v-icon',{attrs:{"size":28}},[_vm._v(" mdi-account-circle ")]),(_vm.user && _vm.user.firstName)?_c('span',{staticClass:"mx-2",domProps:{"textContent":_vm._s(`${_vm.user.firstName}`)}}):(_vm.user && _vm.user.lastName)?_c('span',{staticClass:"mx-2",domProps:{"textContent":_vm._s(`${_vm.user.lastName}`)}}):_c('span',{directives:[{name:"t",rawName:"v-t",value:('pages.app.profile'),expression:"'pages.app.profile'"}],staticClass:"mx-2"}),_c('v-icon',[_vm._v(" mdi-menu-down ")])],1)]}}],null,false,1097040629),model:{value:(_vm.open),callback:function ($$v) {_vm.open=$$v},expression:"open"}},[_c('v-card',[_c('v-list',{attrs:{"subheader":""}},[_c('v-list-item',{on:{"click":_vm.navigate}},[_c('v-list-item-icon',[_c('v-icon',{attrs:{"size":"28"}},[_vm._v(" mdi-account-circle ")])],1),_c('v-list-item-content',[(_vm.user)?[_c('v-list-item-title',[_vm._v(" "+_vm._s(`${_vm.user.firstName} ${_vm.user.lastName}`)+" ")]),_c('v-list-item-subtitle',[_vm._v(" "+_vm._s(`${_vm.user.email}`)+" ")])]:_c('span',{directives:[{name:"t",rawName:"v-t",value:('pages.app.profile'),expression:"'pages.app.profile'"}],staticClass:"mx-2"})],2)],1),_c('v-divider'),(
          _vm.user &&
            _vm.user.accounts &&
            _vm.user.accounts.length &&
            _vm.user.accounts.length != 1 &&
            _vm.user.accounts.length <= 5
        )?_c('v-list-group',{attrs:{"no-action":"","sub-group":""},scopedSlots:_vm._u([{key:"activator",fn:function(){return [_c('v-list-item-content',[_c('v-list-item-title',[_vm._v(_vm._s("Available Accounts"))])],1)]},proxy:true}],null,false,4081559246)},_vm._l((_vm.user.accounts),function(users,idx){return _c('v-list-item',{key:idx,attrs:{"disabled":users.isCurrent},on:{"click":function($event){return _vm.switchUserOnClick(users)}}},[_c('v-list-item-title',[_vm._v(" "+_vm._s(users.name)+" ")]),(users.isCurrent)?_c('v-icon',{attrs:{"color":"green","small":""}},[_vm._v(" mdi-checkbox-marked-circle ")]):_vm._e()],1)}),1):_vm._e(),(_vm.user &&
          _vm.user.accounts &&
          _vm.user.accounts.length &&
          _vm.user.accounts.length > 5)?_c('v-list-item',{on:{"click":_vm.showSwitchUserDialog}},[_c('v-list-item-action',[_c('v-icon',{attrs:{"size":"24"}},[_vm._v(" mdi-account-group ")])],1),_c('v-list-item-title',[_vm._v(" "+_vm._s(_vm.$t('pages.billing.switchAccount'))+" ")])],1):_vm._e(),_c('app-switch-user-dialog',{ref:"userDialogRef",attrs:{"should-show":_vm.confirmDialogOpen,"title":'Available accounts',"user":_vm.user},on:{"close":function($event){_vm.confirmDialogOpen = false},"update:close":function($event){_vm.confirmDialogOpen = false}}}),_c('app-user-invite-dialog',{attrs:{"should-show":_vm.inviteDialogOpen,"title":'User Not Accepted'},on:{"close":function($event){_vm.inviteDialogOpen = false},"update:close":function($event){_vm.inviteDialogOpen = false},"success":_vm.switchUserOnAcceptance,"reject":_vm.switchUserOnAcceptance}}),(_vm.user && _vm.user.accountUserSettings && _vm.user.accountUserSettings.showFeeTrackerDailySettings)?[_c('v-divider'),_c('v-list-item',[_c('v-list-item-icon',[_c('v-icon',{attrs:{"size":"24"}},[_vm._v(" mdi-bell-outline ")])],1),_c('v-list-item-content',[_c('v-list-item-title',[_vm._v(" "+_vm._s(_vm.$t('components.notification.notificationSettings'))+" ")]),_c('v-list-item-subtitle',[_vm._v(" "+_vm._s(_vm.$t('components.notification.notify'))+" ")]),_c('v-sheet',{staticClass:"px-1 py-2"},[_c('v-list-item-subtitle',[_vm._v(" "+_vm._s(_vm.$t('components.notification.feeChanges'))+" ")]),_c('v-switch',{attrs:{"inset":"","disabled":!_vm.isAdmin,"dense":"","change":"","height":"0"},on:{"change":function($event){return _vm.updateSettings(_vm.user.accountUserSettings)}},model:{value:(
                  _vm.user.accountUserSettings.isFeeTrackerDailyFoundEmailEnabled
                ),callback:function ($$v) {_vm.$set(_vm.user.accountUserSettings, "isFeeTrackerDailyFoundEmailEnabled", $$v)},expression:"\n                  user.accountUserSettings.isFeeTrackerDailyFoundEmailEnabled\n                "}}),_c('v-list-item-subtitle',[_vm._v(" "+_vm._s(_vm.$t('components.notification.noFeeChanges'))+" ")]),_c('v-switch',{attrs:{"disabled":!_vm.isAdmin,"inset":"","dense":"","height":"0"},on:{"change":function($event){return _vm.updateSettings(_vm.user.accountUserSettings)}},model:{value:(
                  _vm.user.accountUserSettings
                    .isFeeTrackerDailyNotFoundEmailEnabled
                ),callback:function ($$v) {_vm.$set(_vm.user.accountUserSettings
                    , "isFeeTrackerDailyNotFoundEmailEnabled", $$v)},expression:"\n                  user.accountUserSettings\n                    .isFeeTrackerDailyNotFoundEmailEnabled\n                "}})],1)],1)],1)]:_vm._e(),_c('v-divider'),_c('v-list-item',{on:{"click":_vm.toggleDarkModeHandler}},[_c('v-list-item-icon',[_c('v-icon',{attrs:{"size":"24"}},[_vm._v(" mdi-theme-light-dark ")])],1),_c('v-list-item-content',{directives:[{name:"t",rawName:"v-t",value:('components.userMenu.toggleDarkMode'),expression:"'components.userMenu.toggleDarkMode'"}]})],1),_c('v-list-item',{on:{"click":_vm.logout}},[_c('v-list-item-action',[_c('v-icon',{attrs:{"size":"24"}},[_vm._v(" mdi-exit-to-app ")])],1),_c('v-list-item-title',{directives:[{name:"t",rawName:"v-t",value:('components.userMenu.logout'),expression:"'components.userMenu.logout'"}]})],1)],2)],1)],1):_c('v-skeleton-loader',{attrs:{"type":"button"}})
}
var staticRenderFns = []

export { render, staticRenderFns }