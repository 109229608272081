import ky from 'ky'

import { type Document } from '@/client/purchasing'
import type { IToken } from '@/types/app'
// import { formatUnixDate } from '@/utils/date'
const { VUE_APP_BASE_URL } = process.env

const documentsUrls = {
  fileUpload: (userId: number) => `${VUE_APP_BASE_URL}documents/${userId}`
}

export type DocumentsApiInterface = {
  fileUpload: (token: IToken, userId: number, file: File, types:{ [key: string]: string }) => Promise<Document>
}

// const sortable = (url: string, sort: Array<any> | null): any => {
//   if (sort) {
//     sort.forEach((option) => {
//       url +=
//         `&sort=${option.colId},${option.sort}`
//     })
//   }
//   return url
// }

export default {
  async fileUpload (token: IToken, userId: number, file: File, types:{ [key: string]: string }): Promise<Document> {
    try {
      const data = new FormData()
      data.append('file', file as File)
      let url = documentsUrls.fileUpload(userId)
      if (types !== undefined) url = `${url}?${new URLSearchParams(types).toString()}`
      return await ky.post(url, {
        method: 'POST',
        headers:
        {
          Authorization: `Bearer ${token}`
        },
        body: data
      }).json() as Document
    } catch (error) {
      return Promise.reject(error)
    }
  }

} as DocumentsApiInterface
