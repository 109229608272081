
import * as Sentry from '@sentry/vue'
import { Component, Vue, Watch } from 'vue-property-decorator'
import { State } from 'vuex-class'

import AppDrawer from '@/components/AppDrawer.vue'
import AppToolbar from '@/components/AppToolbar.vue'
import authRoutes from '@/modules/auth/modules/routes'
import ToastsPopup from '@/modules/toasts/components/ToastsPopup.vue'
import type { IUser } from '@/modules/user/types'

const namespaces = {
  app: 'app',
  auth: 'auth',
  user: 'user',
  toasts: 'toasts'
}

@Component({
  components: {
    ToastsPopup,
    AppToolbar,
    AppDrawer
  }
})

export default class App extends Vue {
  @State('name', { namespace: namespaces.app }) appName?: string | null
  @State('user', { namespace: namespaces.user }) user?: IUser | null
  drawer = true

  get showSubroute () {
    const res = !!this.user || ['404', ...authRoutes.flatMap((r) => r.children || r).map(({ name }) => name)].includes(this.$route.name || undefined)
    return res
  }

  @Watch('user')
  userChanged (user: IUser | null) {
    this.$intercom.shutdown()
    this.bootIntercom(user)
    if (user) {
      Sentry.setUser({
        id: user.id?.toString(),
        email: user.email,
        username: user.firstName + ' ' + user.lastName
      })
    } else {
      Sentry.setUser(null)
    }
  }

  mounted (): void {
    this.bootIntercom()
  }

  bootIntercom (user: IUser | null = null): void {
    if (user) {
      const name = (user.firstName && user.lastName) ? `${user.firstName} ${user.lastName}` : ''
      this.$intercom.boot({
        user_id: user.id,
        name,
        email: user.email
      })
    } else {
      this.$intercom.boot()
    }
  }
}
