import * as Sentry from '@sentry/vue'
import ky from 'ky'

import { IDateRange, IProductDetails, IReportUploadFormData, ISummary, UploadTypeEnum } from '@/modules/marketplace-accounts/types'
import { i18n } from '@/plugins/i18n'
import store from '@/store'
import type { IToken } from '@/types/app'
const { VUE_APP_BASE_URL_V1 } = process.env

const summaryUrls = {
  dateRange: (userId: number) => `${VUE_APP_BASE_URL_V1}report/${userId}/date-ranges`,
  summaries: (userId: number, dateFrom:string, dateTo:string) => `${VUE_APP_BASE_URL_V1}report/${userId}/profit-dash/summaries/${dateFrom}/${dateTo}`,
  reportType: (userId: number) => `${VUE_APP_BASE_URL_V1}upload/${userId}/types`,
  uploadReport: (userId: number, reportType:string, year:string, month:string) => `${VUE_APP_BASE_URL_V1}upload/${userId}/${reportType}/${year}/${month}`,
  transactionSku: (userId: number, transactionId:number, sku:string) => `${VUE_APP_BASE_URL_V1}update/${userId}/transaction-sku/${transactionId}/${sku}`,
  productdetails: (userId: number, dateFrom:string, dateTo:string) => `${VUE_APP_BASE_URL_V1}product/${userId}/details/${dateFrom}/${dateTo}`

}
export type SummaryApiInterface = {
  getReportDateRange: (token: IToken, userId: number) => Promise<IDateRange | null>
  getReportSummary: (token: IToken, userId: number, dateFrom: string, dateTo: string) => Promise<ISummary | null>
  getReportType:(token: IToken, userId: number) => Promise<UploadTypeEnum | null>
  getProductDetails: (token: IToken, userId: number, dateFrom: string, dateTo: string) => Promise<IProductDetails | null>
  uploadReport: (token: IToken, formData: IReportUploadFormData) => Promise<void>
  transactionSkuUpdate:(token: IToken, opts: { userId: number, transactionId: number, sku: string }) => Promise<any | null>
}

export default {
  async getReportDateRange (token: IToken, userId: number): Promise<IDateRange | null> {
    try {
      return await ky.get(summaryUrls.dateRange(userId), {
        headers: {
          Authorization: `Bearer ${token}`,
          'Content-Type': 'application/json'
        }
      }).json()
    } catch (e) {
      Sentry.captureException(e)
      await store.dispatch(
        'toasts/addError',
        i18n.t('pages.errors.generic.get', { entityName: 'date range' }),
        { root: true }
      )
      return Promise.resolve(null)
    }
  },
  async getReportSummary (token: IToken, userId: number, dateFrom:string, dateTo:string):Promise<ISummary| null> {
    try {
      return await ky.get(summaryUrls.summaries(userId, dateFrom, dateTo), {
        headers: {
          Authorization: `Bearer ${token}`
        },
        timeout: false
      }).json()
    } catch (e) {
      Sentry.captureException(e)
      await store.dispatch(
        'toasts/addError',
        i18n.t('pages.errors.generic.get', { entityName: 'report summary' }),
        { root: true }
      )
      return Promise.resolve(null)
    }
  },
  async getReportType (token: IToken, userId: number): Promise<UploadTypeEnum | null> {
    try {
      return await ky.get(summaryUrls.reportType(userId), {
        headers: {
          Authorization: `Bearer ${token}`
        }
      }).json()
    } catch (e) {
      Sentry.captureException(e)
      await store.dispatch(
        'toasts/addError',
        i18n.t('pages.errors.generic.get', { entityName: 'report type' }),
        { root: true }
      )
      return Promise.resolve(null)
    }
  },
  async getProductDetails (token: IToken, userId: number, dateFrom:string, dateTo:string):Promise<IProductDetails| null> {
    try {
      return await ky.get(summaryUrls.productdetails(userId, dateFrom, dateTo), {
        headers: {
          Authorization: `Bearer ${token}`
        },
        timeout: false
      }).json()
    } catch (e) {
      Sentry.captureException(e)
      await store.dispatch(
        'toasts/addError',
        i18n.t('pages.errors.generic.get', { entityName: 'product details' }),
        { root: true }
      )
      return Promise.resolve(null)
    }
  },
  async uploadReport (token: IToken, formData: IReportUploadFormData): Promise<any> {
    const { userId, reportType, year, month, file } = formData
    const data = new FormData()
    data.append('file', file as File)
    const result = await fetch(summaryUrls.uploadReport(userId, reportType, year, month), {
      method: 'POST',
      headers:
        {
          Authorization: `Bearer ${token}`
        },
      body: data
    }) as Response
    if (result.ok) return await result.json()
    const message = (await result.json() as { message: string }).message
    Sentry.captureException(message)
    store.dispatch(
      'toasts/addError',
      i18n.t('pages.errors.422.description'),
      { root: true }
    )
    return Promise.reject(message)
  },
  async transactionSkuUpdate (token: IToken, opts: { userId: number, transactionId: number, sku: string }): Promise<any | null> {
    const params = {
      headers:
      {
        Authorization: `Bearer ${token}`,
        'Content-Type': 'application/json'
      }
    }
    try {
      return await ky.patch(summaryUrls.transactionSku(opts.userId, opts.transactionId, opts.sku), params).json()
    } catch (e) {
      return Promise.reject(e)
    }
  }

} as SummaryApiInterface
