/* tslint:disable */
/* eslint-disable */
/**
 * OpenAPI definition
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import type { Configuration } from './configuration';
import type { AxiosPromise, AxiosInstance, RawAxiosRequestConfig } from 'axios';
import globalAxios from 'axios';
// Some imports not used depending on template conditions
// @ts-ignore
import { DUMMY_BASE_URL, assertParamExists, setApiKeyToObject, setBasicAuthToObject, setBearerAuthToObject, setOAuthToObject, setSearchParams, serializeDataIfNeeded, toPathString, createRequestFunction } from './common';
import type { RequestArgs } from './base';
// @ts-ignore
import { BASE_PATH, COLLECTION_FORMATS, BaseAPI, RequiredError, operationServerMap } from './base';

/**
 * 
 * @export
 * @interface Account
 */
export interface Account {
    /**
     * 
     * @type {OffsetDateTime}
     * @memberof Account
     */
    'updatedDateUTCAsDate'?: OffsetDateTime;
    /**
     * 
     * @type {string}
     * @memberof Account
     */
    'Code'?: string;
    /**
     * 
     * @type {string}
     * @memberof Account
     */
    'Name'?: string;
    /**
     * 
     * @type {string}
     * @memberof Account
     */
    'AccountID'?: string;
    /**
     * 
     * @type {string}
     * @memberof Account
     */
    'Type'?: AccountTypeEnum;
    /**
     * 
     * @type {string}
     * @memberof Account
     */
    'BankAccountNumber'?: string;
    /**
     * 
     * @type {string}
     * @memberof Account
     */
    'Status'?: AccountStatusEnum;
    /**
     * 
     * @type {string}
     * @memberof Account
     */
    'Description'?: string;
    /**
     * 
     * @type {string}
     * @memberof Account
     */
    'BankAccountType'?: AccountBankAccountTypeEnum;
    /**
     * 
     * @type {string}
     * @memberof Account
     */
    'CurrencyCode'?: AccountCurrencyCodeEnum;
    /**
     * 
     * @type {string}
     * @memberof Account
     */
    'TaxType'?: string;
    /**
     * 
     * @type {boolean}
     * @memberof Account
     */
    'EnablePaymentsToAccount'?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof Account
     */
    'ShowInExpenseClaims'?: boolean;
    /**
     * 
     * @type {string}
     * @memberof Account
     */
    'Class'?: AccountClassEnum;
    /**
     * 
     * @type {string}
     * @memberof Account
     */
    'SystemAccount'?: AccountSystemAccountEnum;
    /**
     * 
     * @type {string}
     * @memberof Account
     */
    'ReportingCode'?: string;
    /**
     * 
     * @type {string}
     * @memberof Account
     */
    'ReportingCodeName'?: string;
    /**
     * 
     * @type {boolean}
     * @memberof Account
     */
    'HasAttachments'?: boolean;
    /**
     * 
     * @type {string}
     * @memberof Account
     */
    'UpdatedDateUTC'?: string;
    /**
     * 
     * @type {boolean}
     * @memberof Account
     */
    'AddToWatchlist'?: boolean;
    /**
     * 
     * @type {Array<ValidationError>}
     * @memberof Account
     */
    'ValidationErrors'?: Array<ValidationError>;
}

export const AccountTypeEnum = {
    Bank: 'BANK',
    Current: 'CURRENT',
    Currliab: 'CURRLIAB',
    Depreciatn: 'DEPRECIATN',
    Directcosts: 'DIRECTCOSTS',
    Equity: 'EQUITY',
    Expense: 'EXPENSE',
    Fixed: 'FIXED',
    Inventory: 'INVENTORY',
    Liability: 'LIABILITY',
    Noncurrent: 'NONCURRENT',
    Otherincome: 'OTHERINCOME',
    Overheads: 'OVERHEADS',
    Prepayment: 'PREPAYMENT',
    Revenue: 'REVENUE',
    Sales: 'SALES',
    Termliab: 'TERMLIAB',
    Paygliability: 'PAYGLIABILITY',
    Payg: 'PAYG',
    Superannuationexpense: 'SUPERANNUATIONEXPENSE',
    Superannuationliability: 'SUPERANNUATIONLIABILITY',
    Wagesexpense: 'WAGESEXPENSE'
} as const;

export type AccountTypeEnum = typeof AccountTypeEnum[keyof typeof AccountTypeEnum];
export const AccountStatusEnum = {
    Active: 'ACTIVE',
    Archived: 'ARCHIVED',
    Deleted: 'DELETED'
} as const;

export type AccountStatusEnum = typeof AccountStatusEnum[keyof typeof AccountStatusEnum];
export const AccountBankAccountTypeEnum = {
    Bank: 'BANK',
    Creditcard: 'CREDITCARD',
    Paypal: 'PAYPAL',
    None: 'NONE',
    Empty: ''
} as const;

export type AccountBankAccountTypeEnum = typeof AccountBankAccountTypeEnum[keyof typeof AccountBankAccountTypeEnum];
export const AccountCurrencyCodeEnum = {
    Aed: 'AED',
    Afn: 'AFN',
    All: 'ALL',
    Amd: 'AMD',
    Ang: 'ANG',
    Aoa: 'AOA',
    Ars: 'ARS',
    Aud: 'AUD',
    Awg: 'AWG',
    Azn: 'AZN',
    Bam: 'BAM',
    Bbd: 'BBD',
    Bdt: 'BDT',
    Bgn: 'BGN',
    Bhd: 'BHD',
    Bif: 'BIF',
    Bmd: 'BMD',
    Bnd: 'BND',
    Bob: 'BOB',
    Brl: 'BRL',
    Bsd: 'BSD',
    Btn: 'BTN',
    Bwp: 'BWP',
    Byn: 'BYN',
    Byr: 'BYR',
    Bzd: 'BZD',
    Cad: 'CAD',
    Cdf: 'CDF',
    Chf: 'CHF',
    Clp: 'CLP',
    Cny: 'CNY',
    Cop: 'COP',
    Crc: 'CRC',
    Cuc: 'CUC',
    Cup: 'CUP',
    Cve: 'CVE',
    Czk: 'CZK',
    Djf: 'DJF',
    Dkk: 'DKK',
    Dop: 'DOP',
    Dzd: 'DZD',
    Egp: 'EGP',
    Ern: 'ERN',
    Etb: 'ETB',
    Eur: 'EUR',
    Fjd: 'FJD',
    Fkp: 'FKP',
    Gbp: 'GBP',
    Gel: 'GEL',
    Ggp: 'GGP',
    Ghs: 'GHS',
    Gip: 'GIP',
    Gmd: 'GMD',
    Gnf: 'GNF',
    Gtq: 'GTQ',
    Gyd: 'GYD',
    Hkd: 'HKD',
    Hnl: 'HNL',
    Hrk: 'HRK',
    Htg: 'HTG',
    Huf: 'HUF',
    Idr: 'IDR',
    Ils: 'ILS',
    Imp: 'IMP',
    Inr: 'INR',
    Iqd: 'IQD',
    Irr: 'IRR',
    Isk: 'ISK',
    Jep: 'JEP',
    Jmd: 'JMD',
    Jod: 'JOD',
    Jpy: 'JPY',
    Kes: 'KES',
    Kgs: 'KGS',
    Khr: 'KHR',
    Kmf: 'KMF',
    Kpw: 'KPW',
    Krw: 'KRW',
    Kwd: 'KWD',
    Kyd: 'KYD',
    Kzt: 'KZT',
    Lak: 'LAK',
    Lbp: 'LBP',
    Lkr: 'LKR',
    Lrd: 'LRD',
    Lsl: 'LSL',
    Ltl: 'LTL',
    Lyd: 'LYD',
    Mad: 'MAD',
    Mdl: 'MDL',
    Mga: 'MGA',
    Mkd: 'MKD',
    Mmk: 'MMK',
    Mnt: 'MNT',
    Mop: 'MOP',
    Mru: 'MRU',
    Mur: 'MUR',
    Mvr: 'MVR',
    Mwk: 'MWK',
    Mxn: 'MXN',
    Myr: 'MYR',
    Mzn: 'MZN',
    Nad: 'NAD',
    Ngn: 'NGN',
    Nio: 'NIO',
    Nok: 'NOK',
    Npr: 'NPR',
    Nzd: 'NZD',
    Omr: 'OMR',
    Pab: 'PAB',
    Pen: 'PEN',
    Pgk: 'PGK',
    Php: 'PHP',
    Pkr: 'PKR',
    Pln: 'PLN',
    Pyg: 'PYG',
    Qar: 'QAR',
    Ron: 'RON',
    Rsd: 'RSD',
    Rub: 'RUB',
    Rwf: 'RWF',
    Sar: 'SAR',
    Sbd: 'SBD',
    Scr: 'SCR',
    Sdg: 'SDG',
    Sek: 'SEK',
    Sgd: 'SGD',
    Shp: 'SHP',
    Sll: 'SLL',
    Sos: 'SOS',
    Spl: 'SPL',
    Srd: 'SRD',
    Stn: 'STN',
    Svc: 'SVC',
    Syp: 'SYP',
    Szl: 'SZL',
    Thb: 'THB',
    Tjs: 'TJS',
    Tmt: 'TMT',
    Tnd: 'TND',
    Top: 'TOP',
    Try: 'TRY',
    Ttd: 'TTD',
    Tvd: 'TVD',
    Twd: 'TWD',
    Tzs: 'TZS',
    Uah: 'UAH',
    Ugx: 'UGX',
    Usd: 'USD',
    Uyu: 'UYU',
    Uzs: 'UZS',
    Vef: 'VEF',
    Vnd: 'VND',
    Vuv: 'VUV',
    Wst: 'WST',
    Xaf: 'XAF',
    Xcd: 'XCD',
    Xdr: 'XDR',
    Xof: 'XOF',
    Xpf: 'XPF',
    Yer: 'YER',
    Zar: 'ZAR',
    Zmw: 'ZMW',
    Zmk: 'ZMK',
    Zwd: 'ZWD',
    Empty: ''
} as const;

export type AccountCurrencyCodeEnum = typeof AccountCurrencyCodeEnum[keyof typeof AccountCurrencyCodeEnum];
export const AccountClassEnum = {
    Asset: 'ASSET',
    Equity: 'EQUITY',
    Expense: 'EXPENSE',
    Liability: 'LIABILITY',
    Revenue: 'REVENUE'
} as const;

export type AccountClassEnum = typeof AccountClassEnum[keyof typeof AccountClassEnum];
export const AccountSystemAccountEnum = {
    Debtors: 'DEBTORS',
    Creditors: 'CREDITORS',
    Bankcurrencygain: 'BANKCURRENCYGAIN',
    Gst: 'GST',
    Gstonimports: 'GSTONIMPORTS',
    Historical: 'HISTORICAL',
    Realisedcurrencygain: 'REALISEDCURRENCYGAIN',
    Retainedearnings: 'RETAINEDEARNINGS',
    Rounding: 'ROUNDING',
    Trackingtransfers: 'TRACKINGTRANSFERS',
    Unpaidexpclm: 'UNPAIDEXPCLM',
    Unrealisedcurrencygain: 'UNREALISEDCURRENCYGAIN',
    Wagepayables: 'WAGEPAYABLES',
    Cisassets: 'CISASSETS',
    Cisasset: 'CISASSET',
    Cislabour: 'CISLABOUR',
    Cislabourexpense: 'CISLABOUREXPENSE',
    Cislabourincome: 'CISLABOURINCOME',
    Cisliability: 'CISLIABILITY',
    Cismaterials: 'CISMATERIALS',
    Empty: ''
} as const;

export type AccountSystemAccountEnum = typeof AccountSystemAccountEnum[keyof typeof AccountSystemAccountEnum];

/**
 * 
 * @export
 * @interface AccountCode
 */
export interface AccountCode {
    /**
     * 
     * @type {number}
     * @memberof AccountCode
     */
    'id'?: number;
    /**
     * 
     * @type {string}
     * @memberof AccountCode
     */
    'name'?: string;
    /**
     * 
     * @type {string}
     * @memberof AccountCode
     */
    'code'?: string;
    /**
     * 
     * @type {string}
     * @memberof AccountCode
     */
    'description'?: string;
    /**
     * 
     * @type {string}
     * @memberof AccountCode
     */
    'accountCodeId'?: string;
    /**
     * 
     * @type {boolean}
     * @memberof AccountCode
     */
    'isSystemDefault'?: boolean;
    /**
     * 
     * @type {number}
     * @memberof AccountCode
     */
    'userId'?: number;
    /**
     * 
     * @type {number}
     * @memberof AccountCode
     */
    'accountId'?: number;
    /**
     * 
     * @type {string}
     * @memberof AccountCode
     */
    'accountCodeType'?: AccountCodeAccountCodeTypeEnum;
}

export const AccountCodeAccountCodeTypeEnum = {
    Bank: 'BANK',
    Current: 'CURRENT',
    Currliab: 'CURRLIAB',
    Depreciatn: 'DEPRECIATN',
    Directcosts: 'DIRECTCOSTS',
    Equity: 'EQUITY',
    Expense: 'EXPENSE',
    Fixed: 'FIXED',
    Inventory: 'INVENTORY',
    Liability: 'LIABILITY',
    Noncurrent: 'NONCURRENT',
    Otherincome: 'OTHERINCOME',
    Overheads: 'OVERHEADS',
    Prepayment: 'PREPAYMENT',
    Revenue: 'REVENUE',
    Sales: 'SALES',
    Termliab: 'TERMLIAB',
    Paygliability: 'PAYGLIABILITY',
    Payg: 'PAYG',
    Superannuationexpense: 'SUPERANNUATIONEXPENSE',
    Superannuationliability: 'SUPERANNUATIONLIABILITY',
    Wagesexpense: 'WAGESEXPENSE'
} as const;

export type AccountCodeAccountCodeTypeEnum = typeof AccountCodeAccountCodeTypeEnum[keyof typeof AccountCodeAccountCodeTypeEnum];

/**
 * 
 * @export
 * @interface Accounts
 */
export interface Accounts {
    /**
     * 
     * @type {Array<Account>}
     * @memberof Accounts
     */
    'Accounts'?: Array<Account>;
}
/**
 * 
 * @export
 * @interface Chronology
 */
export interface Chronology {
    /**
     * 
     * @type {string}
     * @memberof Chronology
     */
    'id'?: string;
    /**
     * 
     * @type {string}
     * @memberof Chronology
     */
    'calendarType'?: string;
}
/**
 * 
 * @export
 * @interface Currency
 */
export interface Currency {
    /**
     * 
     * @type {number}
     * @memberof Currency
     */
    'id'?: number;
    /**
     * 
     * @type {string}
     * @memberof Currency
     */
    'country'?: string;
    /**
     * 
     * @type {string}
     * @memberof Currency
     */
    'currencyName'?: string;
    /**
     * 
     * @type {string}
     * @memberof Currency
     */
    'code'?: string;
    /**
     * 
     * @type {string}
     * @memberof Currency
     */
    'currencySymbol'?: string;
    /**
     * 
     * @type {number}
     * @memberof Currency
     */
    'sortField'?: number;
    /**
     * 
     * @type {number}
     * @memberof Currency
     */
    'precision'?: number;
}
/**
 * 
 * @export
 * @interface Duration
 */
export interface Duration {
    /**
     * 
     * @type {number}
     * @memberof Duration
     */
    'seconds'?: number;
    /**
     * 
     * @type {number}
     * @memberof Duration
     */
    'nano'?: number;
    /**
     * 
     * @type {Array<TemporalUnit>}
     * @memberof Duration
     */
    'units'?: Array<TemporalUnit>;
    /**
     * 
     * @type {boolean}
     * @memberof Duration
     */
    'zero'?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof Duration
     */
    'negative'?: boolean;
}
/**
 * 
 * @export
 * @interface Instant
 */
export interface Instant {
    /**
     * 
     * @type {number}
     * @memberof Instant
     */
    'nano'?: number;
    /**
     * 
     * @type {number}
     * @memberof Instant
     */
    'epochSecond'?: number;
}
/**
 * 
 * @export
 * @interface Invoice
 */
export interface Invoice {
    /**
     * 
     * @type {number}
     * @memberof Invoice
     */
    'id'?: number;
    /**
     * 
     * @type {string}
     * @memberof Invoice
     */
    'invoiceNumber'?: string;
    /**
     * 
     * @type {number}
     * @memberof Invoice
     */
    'unixDate'?: number;
    /**
     * 
     * @type {string}
     * @memberof Invoice
     */
    'payReferenceNumber'?: string;
    /**
     * 
     * @type {string}
     * @memberof Invoice
     */
    'costType'?: InvoiceCostTypeEnum;
    /**
     * 
     * @type {string}
     * @memberof Invoice
     */
    'trackingNumber'?: string;
    /**
     * 
     * @type {number}
     * @memberof Invoice
     */
    'invoiceTotal'?: number;
    /**
     * 
     * @type {number}
     * @memberof Invoice
     */
    'userId'?: number;
    /**
     * 
     * @type {number}
     * @memberof Invoice
     */
    'accountId'?: number;
    /**
     * 
     * @type {string}
     * @memberof Invoice
     */
    'xeroId'?: string;
    /**
     * 
     * @type {Supplier}
     * @memberof Invoice
     */
    'supplier'?: Supplier;
}

export const InvoiceCostTypeEnum = {
    Shipping: 'SHIPPING',
    FactoryInvoice: 'FACTORY_INVOICE',
    CustomsAndDuties: 'CUSTOMS_AND_DUTIES',
    Inspections: 'INSPECTIONS',
    LocalFreight: 'LOCAL_FREIGHT',
    Photography: 'PHOTOGRAPHY',
    DesignerFees: 'DESIGNER_FEES',
    ProductSamples: 'PRODUCT_SAMPLES',
    ProductTesting: 'PRODUCT_TESTING',
    Storage: 'STORAGE',
    Insurance: 'INSURANCE',
    BankFees: 'BANK_FEES',
    Other: 'OTHER'
} as const;

export type InvoiceCostTypeEnum = typeof InvoiceCostTypeEnum[keyof typeof InvoiceCostTypeEnum];

/**
 * 
 * @export
 * @interface InvoicePayment
 */
export interface InvoicePayment {
    /**
     * 
     * @type {number}
     * @memberof InvoicePayment
     */
    'id'?: number;
    /**
     * 
     * @type {number}
     * @memberof InvoicePayment
     */
    'userId'?: number;
    /**
     * 
     * @type {number}
     * @memberof InvoicePayment
     */
    'accountId'?: number;
    /**
     * 
     * @type {number}
     * @memberof InvoicePayment
     */
    'unixDueDate'?: number;
    /**
     * 
     * @type {number}
     * @memberof InvoicePayment
     */
    'total'?: number;
    /**
     * 
     * @type {number}
     * @memberof InvoicePayment
     */
    'paidAdjusted'?: number;
    /**
     * 
     * @type {number}
     * @memberof InvoicePayment
     */
    'balance'?: number;
    /**
     * 
     * @type {Invoice}
     * @memberof InvoicePayment
     */
    'invoice'?: Invoice;
    /**
     * 
     * @type {User}
     * @memberof InvoicePayment
     */
    'assignedToUser'?: User;
    /**
     * 
     * @type {Array<Payment>}
     * @memberof InvoicePayment
     */
    'payments'?: Array<Payment>;
    /**
     * 
     * @type {string}
     * @memberof InvoicePayment
     */
    'invoicePaymentStatus'?: InvoicePaymentInvoicePaymentStatusEnum;
    /**
     * 
     * @type {AccountCode}
     * @memberof InvoicePayment
     */
    'accountCode'?: AccountCode;
    /**
     * 
     * @type {string}
     * @memberof InvoicePayment
     */
    'xeroId'?: string;
}

export const InvoicePaymentInvoicePaymentStatusEnum = {
    Paid: 'PAID',
    Balance: 'BALANCE',
    Closed: 'CLOSED'
} as const;

export type InvoicePaymentInvoicePaymentStatusEnum = typeof InvoicePaymentInvoicePaymentStatusEnum[keyof typeof InvoicePaymentInvoicePaymentStatusEnum];

/**
 * 
 * @export
 * @interface LocalDateTime
 */
export interface LocalDateTime {
    /**
     * 
     * @type {number}
     * @memberof LocalDateTime
     */
    'nano'?: number;
    /**
     * 
     * @type {number}
     * @memberof LocalDateTime
     */
    'year'?: number;
    /**
     * 
     * @type {number}
     * @memberof LocalDateTime
     */
    'monthValue'?: number;
    /**
     * 
     * @type {number}
     * @memberof LocalDateTime
     */
    'dayOfMonth'?: number;
    /**
     * 
     * @type {number}
     * @memberof LocalDateTime
     */
    'hour'?: number;
    /**
     * 
     * @type {number}
     * @memberof LocalDateTime
     */
    'minute'?: number;
    /**
     * 
     * @type {number}
     * @memberof LocalDateTime
     */
    'second'?: number;
    /**
     * 
     * @type {string}
     * @memberof LocalDateTime
     */
    'dayOfWeek'?: LocalDateTimeDayOfWeekEnum;
    /**
     * 
     * @type {number}
     * @memberof LocalDateTime
     */
    'dayOfYear'?: number;
    /**
     * 
     * @type {string}
     * @memberof LocalDateTime
     */
    'month'?: LocalDateTimeMonthEnum;
    /**
     * 
     * @type {Chronology}
     * @memberof LocalDateTime
     */
    'chronology'?: Chronology;
}

export const LocalDateTimeDayOfWeekEnum = {
    Monday: 'MONDAY',
    Tuesday: 'TUESDAY',
    Wednesday: 'WEDNESDAY',
    Thursday: 'THURSDAY',
    Friday: 'FRIDAY',
    Saturday: 'SATURDAY',
    Sunday: 'SUNDAY'
} as const;

export type LocalDateTimeDayOfWeekEnum = typeof LocalDateTimeDayOfWeekEnum[keyof typeof LocalDateTimeDayOfWeekEnum];
export const LocalDateTimeMonthEnum = {
    January: 'JANUARY',
    February: 'FEBRUARY',
    March: 'MARCH',
    April: 'APRIL',
    May: 'MAY',
    June: 'JUNE',
    July: 'JULY',
    August: 'AUGUST',
    September: 'SEPTEMBER',
    October: 'OCTOBER',
    November: 'NOVEMBER',
    December: 'DECEMBER'
} as const;

export type LocalDateTimeMonthEnum = typeof LocalDateTimeMonthEnum[keyof typeof LocalDateTimeMonthEnum];

/**
 * 
 * @export
 * @interface LocalTime
 */
export interface LocalTime {
    /**
     * 
     * @type {number}
     * @memberof LocalTime
     */
    'hour'?: number;
    /**
     * 
     * @type {number}
     * @memberof LocalTime
     */
    'minute'?: number;
    /**
     * 
     * @type {number}
     * @memberof LocalTime
     */
    'second'?: number;
    /**
     * 
     * @type {number}
     * @memberof LocalTime
     */
    'nano'?: number;
}
/**
 * 
 * @export
 * @interface NotificationDto
 */
export interface NotificationDto {
    /**
     * 
     * @type {number}
     * @memberof NotificationDto
     */
    'id'?: number;
    /**
     * 
     * @type {number}
     * @memberof NotificationDto
     */
    'userId'?: number;
    /**
     * 
     * @type {number}
     * @memberof NotificationDto
     */
    'accountId'?: number;
    /**
     * 
     * @type {string}
     * @memberof NotificationDto
     */
    'notificationStatus'?: NotificationDtoNotificationStatusEnum;
    /**
     * 
     * @type {string}
     * @memberof NotificationDto
     */
    'summary'?: string;
    /**
     * 
     * @type {string}
     * @memberof NotificationDto
     */
    'message'?: string;
    /**
     * 
     * @type {string}
     * @memberof NotificationDto
     */
    'notificationType'?: NotificationDtoNotificationTypeEnum;
    /**
     * 
     * @type {string}
     * @memberof NotificationDto
     */
    'notificationSource'?: NotificationDtoNotificationSourceEnum;
    /**
     * 
     * @type {string}
     * @memberof NotificationDto
     */
    'entityType'?: string;
    /**
     * 
     * @type {string}
     * @memberof NotificationDto
     */
    'entityId'?: string;
    /**
     * 
     * @type {string}
     * @memberof NotificationDto
     */
    'messageDateTime'?: string;
}

export const NotificationDtoNotificationStatusEnum = {
    Unread: 'UNREAD',
    Read: 'READ',
    Dismissed: 'DISMISSED'
} as const;

export type NotificationDtoNotificationStatusEnum = typeof NotificationDtoNotificationStatusEnum[keyof typeof NotificationDtoNotificationStatusEnum];
export const NotificationDtoNotificationTypeEnum = {
    Info: 'INFO',
    ActionNeeded: 'ACTION_NEEDED',
    Error: 'ERROR'
} as const;

export type NotificationDtoNotificationTypeEnum = typeof NotificationDtoNotificationTypeEnum[keyof typeof NotificationDtoNotificationTypeEnum];
export const NotificationDtoNotificationSourceEnum = {
    GatewayService: 'GATEWAY_SERVICE',
    InvoiceService: 'INVOICE_SERVICE',
    NotificationService: 'NOTIFICATION_SERVICE',
    ProductService: 'PRODUCT_SERVICE',
    ReportingService: 'REPORTING_SERVICE',
    SchedulerService: 'SCHEDULER_SERVICE',
    SupplierService: 'SUPPLIER_SERVICE',
    ShopifyService: 'SHOPIFY_SERVICE',
    AmazonService: 'AMAZON_SERVICE',
    UserService: 'USER_SERVICE',
    XeroService: 'XERO_SERVICE',
    OrdersService: 'ORDERS_SERVICE',
    Sv1Service: 'SV1_SERVICE',
    PurchasingService: 'PURCHASING_SERVICE'
} as const;

export type NotificationDtoNotificationSourceEnum = typeof NotificationDtoNotificationSourceEnum[keyof typeof NotificationDtoNotificationSourceEnum];

/**
 * 
 * @export
 * @interface OffsetDateTime
 */
export interface OffsetDateTime {
    /**
     * 
     * @type {ZoneOffset}
     * @memberof OffsetDateTime
     */
    'offset'?: ZoneOffset;
    /**
     * 
     * @type {number}
     * @memberof OffsetDateTime
     */
    'nano'?: number;
    /**
     * 
     * @type {number}
     * @memberof OffsetDateTime
     */
    'year'?: number;
    /**
     * 
     * @type {number}
     * @memberof OffsetDateTime
     */
    'monthValue'?: number;
    /**
     * 
     * @type {number}
     * @memberof OffsetDateTime
     */
    'dayOfMonth'?: number;
    /**
     * 
     * @type {number}
     * @memberof OffsetDateTime
     */
    'hour'?: number;
    /**
     * 
     * @type {number}
     * @memberof OffsetDateTime
     */
    'minute'?: number;
    /**
     * 
     * @type {number}
     * @memberof OffsetDateTime
     */
    'second'?: number;
    /**
     * 
     * @type {string}
     * @memberof OffsetDateTime
     */
    'dayOfWeek'?: OffsetDateTimeDayOfWeekEnum;
    /**
     * 
     * @type {number}
     * @memberof OffsetDateTime
     */
    'dayOfYear'?: number;
    /**
     * 
     * @type {string}
     * @memberof OffsetDateTime
     */
    'month'?: OffsetDateTimeMonthEnum;
}

export const OffsetDateTimeDayOfWeekEnum = {
    Monday: 'MONDAY',
    Tuesday: 'TUESDAY',
    Wednesday: 'WEDNESDAY',
    Thursday: 'THURSDAY',
    Friday: 'FRIDAY',
    Saturday: 'SATURDAY',
    Sunday: 'SUNDAY'
} as const;

export type OffsetDateTimeDayOfWeekEnum = typeof OffsetDateTimeDayOfWeekEnum[keyof typeof OffsetDateTimeDayOfWeekEnum];
export const OffsetDateTimeMonthEnum = {
    January: 'JANUARY',
    February: 'FEBRUARY',
    March: 'MARCH',
    April: 'APRIL',
    May: 'MAY',
    June: 'JUNE',
    July: 'JULY',
    August: 'AUGUST',
    September: 'SEPTEMBER',
    October: 'OCTOBER',
    November: 'NOVEMBER',
    December: 'DECEMBER'
} as const;

export type OffsetDateTimeMonthEnum = typeof OffsetDateTimeMonthEnum[keyof typeof OffsetDateTimeMonthEnum];

/**
 * 
 * @export
 * @interface Payment
 */
export interface Payment {
    /**
     * 
     * @type {number}
     * @memberof Payment
     */
    'id'?: number;
    /**
     * 
     * @type {number}
     * @memberof Payment
     */
    'invoiceId'?: number;
    /**
     * 
     * @type {number}
     * @memberof Payment
     */
    'unixPaymentDate'?: number;
    /**
     * 
     * @type {string}
     * @memberof Payment
     */
    'terms'?: string;
    /**
     * 
     * @type {number}
     * @memberof Payment
     */
    'amount'?: number;
    /**
     * 
     * @type {string}
     * @memberof Payment
     */
    'description'?: string;
    /**
     * 
     * @type {number}
     * @memberof Payment
     */
    'unixDueDate'?: number;
    /**
     * 
     * @type {AccountCode}
     * @memberof Payment
     */
    'paidFromAccount'?: AccountCode;
    /**
     * 
     * @type {Currency}
     * @memberof Payment
     */
    'currency'?: Currency;
    /**
     * 
     * @type {string}
     * @memberof Payment
     */
    'paymentStatus'?: PaymentPaymentStatusEnum;
    /**
     * 
     * @type {InvoicePayment}
     * @memberof Payment
     */
    'invoicePayment'?: InvoicePayment;
    /**
     * 
     * @type {number}
     * @memberof Payment
     */
    'userId'?: number;
    /**
     * 
     * @type {number}
     * @memberof Payment
     */
    'accountId'?: number;
    /**
     * 
     * @type {string}
     * @memberof Payment
     */
    'paymentReference'?: string;
    /**
     * 
     * @type {string}
     * @memberof Payment
     */
    'xeroId'?: string;
}

export const PaymentPaymentStatusEnum = {
    Pay: 'PAY',
    Paid: 'PAID',
    Overdue: 'OVERDUE',
    Pending: 'PENDING'
} as const;

export type PaymentPaymentStatusEnum = typeof PaymentPaymentStatusEnum[keyof typeof PaymentPaymentStatusEnum];

/**
 * 
 * @export
 * @interface Supplier
 */
export interface Supplier {
    /**
     * 
     * @type {number}
     * @memberof Supplier
     */
    'id'?: number;
    /**
     * 
     * @type {string}
     * @memberof Supplier
     */
    'xeroId'?: string;
    /**
     * 
     * @type {string}
     * @memberof Supplier
     */
    'telephoneNumber'?: string;
    /**
     * 
     * @type {string}
     * @memberof Supplier
     */
    'email'?: string;
    /**
     * 
     * @type {string}
     * @memberof Supplier
     */
    'companyName'?: string;
    /**
     * 
     * @type {number}
     * @memberof Supplier
     */
    'userId'?: number;
    /**
     * 
     * @type {number}
     * @memberof Supplier
     */
    'accountId'?: number;
    /**
     * 
     * @type {string}
     * @memberof Supplier
     */
    'addressLine1'?: string;
    /**
     * 
     * @type {string}
     * @memberof Supplier
     */
    'addressLine2'?: string;
    /**
     * 
     * @type {string}
     * @memberof Supplier
     */
    'addressLine3'?: string;
    /**
     * 
     * @type {string}
     * @memberof Supplier
     */
    'addressLine4'?: string;
    /**
     * 
     * @type {string}
     * @memberof Supplier
     */
    'city'?: string;
    /**
     * 
     * @type {string}
     * @memberof Supplier
     */
    'zipOrPostCode'?: string;
    /**
     * 
     * @type {string}
     * @memberof Supplier
     */
    'stateOrProvince'?: string;
    /**
     * 
     * @type {string}
     * @memberof Supplier
     */
    'country'?: string;
}
/**
 * 
 * @export
 * @interface TemporalUnit
 */
export interface TemporalUnit {
    /**
     * 
     * @type {boolean}
     * @memberof TemporalUnit
     */
    'dateBased'?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof TemporalUnit
     */
    'timeBased'?: boolean;
    /**
     * 
     * @type {Duration}
     * @memberof TemporalUnit
     */
    'duration'?: Duration;
    /**
     * 
     * @type {boolean}
     * @memberof TemporalUnit
     */
    'durationEstimated'?: boolean;
}
/**
 * 
 * @export
 * @interface User
 */
export interface User {
    /**
     * 
     * @type {number}
     * @memberof User
     */
    'id'?: number;
    /**
     * 
     * @type {string}
     * @memberof User
     */
    'email'?: string;
    /**
     * 
     * @type {number}
     * @memberof User
     */
    'currentAccountId'?: number;
    /**
     * 
     * @type {number}
     * @memberof User
     */
    'ownedAccountId'?: number;
}
/**
 * 
 * @export
 * @interface ValidationError
 */
export interface ValidationError {
    /**
     * 
     * @type {string}
     * @memberof ValidationError
     */
    'Message'?: string;
}
/**
 * 
 * @export
 * @interface XeroApiClient
 */
export interface XeroApiClient {
    /**
     * 
     * @type {string}
     * @memberof XeroApiClient
     */
    'clientId'?: string;
    /**
     * 
     * @type {string}
     * @memberof XeroApiClient
     */
    'clientSecret'?: string;
    /**
     * 
     * @type {string}
     * @memberof XeroApiClient
     */
    'redirectUrl'?: string;
    /**
     * 
     * @type {Array<string>}
     * @memberof XeroApiClient
     */
    'scopes'?: Array<string>;
    /**
     * 
     * @type {number}
     * @memberof XeroApiClient
     */
    'sessionState'?: number;
}
/**
 * 
 * @export
 * @interface XeroCodeRequestDto
 */
export interface XeroCodeRequestDto {
    /**
     * 
     * @type {string}
     * @memberof XeroCodeRequestDto
     */
    'code'?: string;
    /**
     * 
     * @type {string}
     * @memberof XeroCodeRequestDto
     */
    'scope'?: string;
    /**
     * 
     * @type {string}
     * @memberof XeroCodeRequestDto
     */
    'sessionState'?: string;
    /**
     * 
     * @type {number}
     * @memberof XeroCodeRequestDto
     */
    'state'?: number;
}
/**
 * 
 * @export
 * @interface XeroOAuthToken
 */
export interface XeroOAuthToken {
    /**
     * 
     * @type {number}
     * @memberof XeroOAuthToken
     */
    'id'?: number;
    /**
     * 
     * @type {string}
     * @memberof XeroOAuthToken
     */
    'accessToken'?: string;
    /**
     * 
     * @type {string}
     * @memberof XeroOAuthToken
     */
    'refreshToken'?: string;
    /**
     * 
     * @type {number}
     * @memberof XeroOAuthToken
     */
    'expiresInSeconds'?: number;
    /**
     * 
     * @type {string}
     * @memberof XeroOAuthToken
     */
    'xeroTenantId'?: string;
    /**
     * 
     * @type {number}
     * @memberof XeroOAuthToken
     */
    'userId'?: number;
    /**
     * 
     * @type {number}
     * @memberof XeroOAuthToken
     */
    'expiresAt'?: number;
    /**
     * 
     * @type {number}
     * @memberof XeroOAuthToken
     */
    'accountId'?: number;
}
/**
 * 
 * @export
 * @interface ZoneOffset
 */
export interface ZoneOffset {
    /**
     * 
     * @type {number}
     * @memberof ZoneOffset
     */
    'totalSeconds'?: number;
    /**
     * 
     * @type {string}
     * @memberof ZoneOffset
     */
    'id'?: string;
    /**
     * 
     * @type {ZoneRules}
     * @memberof ZoneOffset
     */
    'rules'?: ZoneRules;
}
/**
 * 
 * @export
 * @interface ZoneOffsetTransition
 */
export interface ZoneOffsetTransition {
    /**
     * 
     * @type {ZoneOffset}
     * @memberof ZoneOffsetTransition
     */
    'offsetBefore'?: ZoneOffset;
    /**
     * 
     * @type {ZoneOffset}
     * @memberof ZoneOffsetTransition
     */
    'offsetAfter'?: ZoneOffset;
    /**
     * 
     * @type {Instant}
     * @memberof ZoneOffsetTransition
     */
    'instant'?: Instant;
    /**
     * 
     * @type {Duration}
     * @memberof ZoneOffsetTransition
     */
    'duration'?: Duration;
    /**
     * 
     * @type {boolean}
     * @memberof ZoneOffsetTransition
     */
    'gap'?: boolean;
    /**
     * 
     * @type {LocalDateTime}
     * @memberof ZoneOffsetTransition
     */
    'dateTimeBefore'?: LocalDateTime;
    /**
     * 
     * @type {LocalDateTime}
     * @memberof ZoneOffsetTransition
     */
    'dateTimeAfter'?: LocalDateTime;
    /**
     * 
     * @type {boolean}
     * @memberof ZoneOffsetTransition
     */
    'overlap'?: boolean;
}
/**
 * 
 * @export
 * @interface ZoneOffsetTransitionRule
 */
export interface ZoneOffsetTransitionRule {
    /**
     * 
     * @type {string}
     * @memberof ZoneOffsetTransitionRule
     */
    'month'?: ZoneOffsetTransitionRuleMonthEnum;
    /**
     * 
     * @type {string}
     * @memberof ZoneOffsetTransitionRule
     */
    'timeDefinition'?: ZoneOffsetTransitionRuleTimeDefinitionEnum;
    /**
     * 
     * @type {ZoneOffset}
     * @memberof ZoneOffsetTransitionRule
     */
    'standardOffset'?: ZoneOffset;
    /**
     * 
     * @type {ZoneOffset}
     * @memberof ZoneOffsetTransitionRule
     */
    'offsetBefore'?: ZoneOffset;
    /**
     * 
     * @type {ZoneOffset}
     * @memberof ZoneOffsetTransitionRule
     */
    'offsetAfter'?: ZoneOffset;
    /**
     * 
     * @type {string}
     * @memberof ZoneOffsetTransitionRule
     */
    'dayOfWeek'?: ZoneOffsetTransitionRuleDayOfWeekEnum;
    /**
     * 
     * @type {number}
     * @memberof ZoneOffsetTransitionRule
     */
    'dayOfMonthIndicator'?: number;
    /**
     * 
     * @type {LocalTime}
     * @memberof ZoneOffsetTransitionRule
     */
    'localTime'?: LocalTime;
    /**
     * 
     * @type {boolean}
     * @memberof ZoneOffsetTransitionRule
     */
    'midnightEndOfDay'?: boolean;
}

export const ZoneOffsetTransitionRuleMonthEnum = {
    January: 'JANUARY',
    February: 'FEBRUARY',
    March: 'MARCH',
    April: 'APRIL',
    May: 'MAY',
    June: 'JUNE',
    July: 'JULY',
    August: 'AUGUST',
    September: 'SEPTEMBER',
    October: 'OCTOBER',
    November: 'NOVEMBER',
    December: 'DECEMBER'
} as const;

export type ZoneOffsetTransitionRuleMonthEnum = typeof ZoneOffsetTransitionRuleMonthEnum[keyof typeof ZoneOffsetTransitionRuleMonthEnum];
export const ZoneOffsetTransitionRuleTimeDefinitionEnum = {
    Utc: 'UTC',
    Wall: 'WALL',
    Standard: 'STANDARD'
} as const;

export type ZoneOffsetTransitionRuleTimeDefinitionEnum = typeof ZoneOffsetTransitionRuleTimeDefinitionEnum[keyof typeof ZoneOffsetTransitionRuleTimeDefinitionEnum];
export const ZoneOffsetTransitionRuleDayOfWeekEnum = {
    Monday: 'MONDAY',
    Tuesday: 'TUESDAY',
    Wednesday: 'WEDNESDAY',
    Thursday: 'THURSDAY',
    Friday: 'FRIDAY',
    Saturday: 'SATURDAY',
    Sunday: 'SUNDAY'
} as const;

export type ZoneOffsetTransitionRuleDayOfWeekEnum = typeof ZoneOffsetTransitionRuleDayOfWeekEnum[keyof typeof ZoneOffsetTransitionRuleDayOfWeekEnum];

/**
 * 
 * @export
 * @interface ZoneRules
 */
export interface ZoneRules {
    /**
     * 
     * @type {boolean}
     * @memberof ZoneRules
     */
    'fixedOffset'?: boolean;
    /**
     * 
     * @type {Array<ZoneOffsetTransition>}
     * @memberof ZoneRules
     */
    'transitions'?: Array<ZoneOffsetTransition>;
    /**
     * 
     * @type {Array<ZoneOffsetTransitionRule>}
     * @memberof ZoneRules
     */
    'transitionRules'?: Array<ZoneOffsetTransitionRule>;
}

/**
 * XeroAuthControllerApi - axios parameter creator
 * @export
 */
export const XeroAuthControllerApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @param {number} userId 
         * @param {XeroCodeRequestDto} [xeroCodeRequestDto] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createNewOauthToken: async (userId: number, xeroCodeRequestDto?: XeroCodeRequestDto, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'userId' is not null or undefined
            assertParamExists('createNewOauthToken', 'userId', userId)
            const localVarPath = `/xero/{userId}/oauth/generate`
                .replace(`{${"userId"}}`, encodeURIComponent(String(userId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(xeroCodeRequestDto, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} userId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteOauthTokenByUserId: async (userId: number, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'userId' is not null or undefined
            assertParamExists('deleteOauthTokenByUserId', 'userId', userId)
            const localVarPath = `/xero/{userId}/oauth`
                .replace(`{${"userId"}}`, encodeURIComponent(String(userId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} userId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getApiClientByUserId: async (userId: number, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'userId' is not null or undefined
            assertParamExists('getApiClientByUserId', 'userId', userId)
            const localVarPath = `/xero/{userId}/api-client`
                .replace(`{${"userId"}}`, encodeURIComponent(String(userId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} userId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getAuthCodeForUserById: async (userId: number, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'userId' is not null or undefined
            assertParamExists('getAuthCodeForUserById', 'userId', userId)
            const localVarPath = `/xero/{userId}/oauth/code-request-url`
                .replace(`{${"userId"}}`, encodeURIComponent(String(userId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} userId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getOauthTokenByUserId: async (userId: number, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'userId' is not null or undefined
            assertParamExists('getOauthTokenByUserId', 'userId', userId)
            const localVarPath = `/xero/{userId}/oauth`
                .replace(`{${"userId"}}`, encodeURIComponent(String(userId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * XeroAuthControllerApi - functional programming interface
 * @export
 */
export const XeroAuthControllerApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = XeroAuthControllerApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @param {number} userId 
         * @param {XeroCodeRequestDto} [xeroCodeRequestDto] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async createNewOauthToken(userId: number, xeroCodeRequestDto?: XeroCodeRequestDto, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<XeroOAuthToken>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.createNewOauthToken(userId, xeroCodeRequestDto, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['XeroAuthControllerApi.createNewOauthToken']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @param {number} userId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async deleteOauthTokenByUserId(userId: number, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<{ [key: string]: string; }>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.deleteOauthTokenByUserId(userId, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['XeroAuthControllerApi.deleteOauthTokenByUserId']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @param {number} userId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getApiClientByUserId(userId: number, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<XeroApiClient>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getApiClientByUserId(userId, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['XeroAuthControllerApi.getApiClientByUserId']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @param {number} userId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getAuthCodeForUserById(userId: number, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<string>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getAuthCodeForUserById(userId, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['XeroAuthControllerApi.getAuthCodeForUserById']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @param {number} userId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getOauthTokenByUserId(userId: number, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<XeroOAuthToken>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getOauthTokenByUserId(userId, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['XeroAuthControllerApi.getOauthTokenByUserId']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
    }
};

/**
 * XeroAuthControllerApi - factory interface
 * @export
 */
export const XeroAuthControllerApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = XeroAuthControllerApiFp(configuration)
    return {
        /**
         * 
         * @param {number} userId 
         * @param {XeroCodeRequestDto} [xeroCodeRequestDto] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createNewOauthToken(userId: number, xeroCodeRequestDto?: XeroCodeRequestDto, options?: any): AxiosPromise<XeroOAuthToken> {
            return localVarFp.createNewOauthToken(userId, xeroCodeRequestDto, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {number} userId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteOauthTokenByUserId(userId: number, options?: any): AxiosPromise<{ [key: string]: string; }> {
            return localVarFp.deleteOauthTokenByUserId(userId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {number} userId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getApiClientByUserId(userId: number, options?: any): AxiosPromise<XeroApiClient> {
            return localVarFp.getApiClientByUserId(userId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {number} userId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getAuthCodeForUserById(userId: number, options?: any): AxiosPromise<string> {
            return localVarFp.getAuthCodeForUserById(userId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {number} userId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getOauthTokenByUserId(userId: number, options?: any): AxiosPromise<Array<XeroOAuthToken>> {
            return localVarFp.getOauthTokenByUserId(userId, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * XeroAuthControllerApi - object-oriented interface
 * @export
 * @class XeroAuthControllerApi
 * @extends {BaseAPI}
 */
export class XeroAuthControllerApi extends BaseAPI {
    /**
     * 
     * @param {number} userId 
     * @param {XeroCodeRequestDto} [xeroCodeRequestDto] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof XeroAuthControllerApi
     */
    public createNewOauthToken(userId: number, xeroCodeRequestDto?: XeroCodeRequestDto, options?: RawAxiosRequestConfig) {
        return XeroAuthControllerApiFp(this.configuration).createNewOauthToken(userId, xeroCodeRequestDto, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {number} userId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof XeroAuthControllerApi
     */
    public deleteOauthTokenByUserId(userId: number, options?: RawAxiosRequestConfig) {
        return XeroAuthControllerApiFp(this.configuration).deleteOauthTokenByUserId(userId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {number} userId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof XeroAuthControllerApi
     */
    public getApiClientByUserId(userId: number, options?: RawAxiosRequestConfig) {
        return XeroAuthControllerApiFp(this.configuration).getApiClientByUserId(userId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {number} userId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof XeroAuthControllerApi
     */
    public getAuthCodeForUserById(userId: number, options?: RawAxiosRequestConfig) {
        return XeroAuthControllerApiFp(this.configuration).getAuthCodeForUserById(userId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {number} userId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof XeroAuthControllerApi
     */
    public getOauthTokenByUserId(userId: number, options?: RawAxiosRequestConfig) {
        return XeroAuthControllerApiFp(this.configuration).getOauthTokenByUserId(userId, options).then((request) => request(this.axios, this.basePath));
    }
}



/**
 * XeroControllerApi - axios parameter creator
 * @export
 */
export const XeroControllerApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @param {number} userId 
         * @param {NotificationDto} [notificationDto] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        sendNotification: async (userId: number, notificationDto?: NotificationDto, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'userId' is not null or undefined
            assertParamExists('sendNotification', 'userId', userId)
            const localVarPath = `/xero/{userId}/notification`
                .replace(`{${"userId"}}`, encodeURIComponent(String(userId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(notificationDto, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * XeroControllerApi - functional programming interface
 * @export
 */
export const XeroControllerApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = XeroControllerApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @param {number} userId 
         * @param {NotificationDto} [notificationDto] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async sendNotification(userId: number, notificationDto?: NotificationDto, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<NotificationDto>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.sendNotification(userId, notificationDto, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['XeroControllerApi.sendNotification']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
    }
};

/**
 * XeroControllerApi - factory interface
 * @export
 */
export const XeroControllerApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = XeroControllerApiFp(configuration)
    return {
        /**
         * 
         * @param {number} userId 
         * @param {NotificationDto} [notificationDto] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        sendNotification(userId: number, notificationDto?: NotificationDto, options?: any): AxiosPromise<NotificationDto> {
            return localVarFp.sendNotification(userId, notificationDto, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * XeroControllerApi - object-oriented interface
 * @export
 * @class XeroControllerApi
 * @extends {BaseAPI}
 */
export class XeroControllerApi extends BaseAPI {
    /**
     * 
     * @param {number} userId 
     * @param {NotificationDto} [notificationDto] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof XeroControllerApi
     */
    public sendNotification(userId: number, notificationDto?: NotificationDto, options?: RawAxiosRequestConfig) {
        return XeroControllerApiFp(this.configuration).sendNotification(userId, notificationDto, options).then((request) => request(this.axios, this.basePath));
    }
}



/**
 * XeroSyncControllerApi - axios parameter creator
 * @export
 */
export const XeroSyncControllerApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @param {number} userId 
         * @param {Array<Supplier>} [supplier] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createOrUpdateXeroContact: async (userId: number, supplier?: Array<Supplier>, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'userId' is not null or undefined
            assertParamExists('createOrUpdateXeroContact', 'userId', userId)
            const localVarPath = `/xero/{userId}/suppliers`
                .replace(`{${"userId"}}`, encodeURIComponent(String(userId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(supplier, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} userId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        fetchAccountsFromXero: async (userId: number, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'userId' is not null or undefined
            assertParamExists('fetchAccountsFromXero', 'userId', userId)
            const localVarPath = `/xero/{userId}/account-codes`
                .replace(`{${"userId"}}`, encodeURIComponent(String(userId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} userId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        fetchSuppliersFromXero: async (userId: number, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'userId' is not null or undefined
            assertParamExists('fetchSuppliersFromXero', 'userId', userId)
            const localVarPath = `/xero/{userId}/suppliers`
                .replace(`{${"userId"}}`, encodeURIComponent(String(userId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} userId 
         * @param {string} xeroInvoiceId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getXeroInvoiceById: async (userId: number, xeroInvoiceId: string, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'userId' is not null or undefined
            assertParamExists('getXeroInvoiceById', 'userId', userId)
            // verify required parameter 'xeroInvoiceId' is not null or undefined
            assertParamExists('getXeroInvoiceById', 'xeroInvoiceId', xeroInvoiceId)
            const localVarPath = `/xero/{userId}/bills/{xeroInvoiceId}`
                .replace(`{${"userId"}}`, encodeURIComponent(String(userId)))
                .replace(`{${"xeroInvoiceId"}}`, encodeURIComponent(String(xeroInvoiceId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} userId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        syncAccountCosesFromXero: async (userId: number, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'userId' is not null or undefined
            assertParamExists('syncAccountCosesFromXero', 'userId', userId)
            const localVarPath = `/xero/{userId}/account-codes/sync`
                .replace(`{${"userId"}}`, encodeURIComponent(String(userId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} userId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        syncSuppliersFromXero: async (userId: number, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'userId' is not null or undefined
            assertParamExists('syncSuppliersFromXero', 'userId', userId)
            const localVarPath = `/xero/{userId}/suppliers/sync`
                .replace(`{${"userId"}}`, encodeURIComponent(String(userId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * XeroSyncControllerApi - functional programming interface
 * @export
 */
export const XeroSyncControllerApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = XeroSyncControllerApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @param {number} userId 
         * @param {Array<Supplier>} [supplier] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async createOrUpdateXeroContact(userId: number, supplier?: Array<Supplier>, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<Supplier>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.createOrUpdateXeroContact(userId, supplier, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['XeroSyncControllerApi.createOrUpdateXeroContact']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @param {number} userId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async fetchAccountsFromXero(userId: number, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Accounts>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.fetchAccountsFromXero(userId, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['XeroSyncControllerApi.fetchAccountsFromXero']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @param {number} userId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async fetchSuppliersFromXero(userId: number, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<Supplier>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.fetchSuppliersFromXero(userId, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['XeroSyncControllerApi.fetchSuppliersFromXero']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @param {number} userId 
         * @param {string} xeroInvoiceId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getXeroInvoiceById(userId: number, xeroInvoiceId: string, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<InvoicePayment>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getXeroInvoiceById(userId, xeroInvoiceId, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['XeroSyncControllerApi.getXeroInvoiceById']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @param {number} userId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async syncAccountCosesFromXero(userId: number, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<AccountCode>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.syncAccountCosesFromXero(userId, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['XeroSyncControllerApi.syncAccountCosesFromXero']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @param {number} userId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async syncSuppliersFromXero(userId: number, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<Supplier>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.syncSuppliersFromXero(userId, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['XeroSyncControllerApi.syncSuppliersFromXero']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
    }
};

/**
 * XeroSyncControllerApi - factory interface
 * @export
 */
export const XeroSyncControllerApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = XeroSyncControllerApiFp(configuration)
    return {
        /**
         * 
         * @param {number} userId 
         * @param {Array<Supplier>} [supplier] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createOrUpdateXeroContact(userId: number, supplier?: Array<Supplier>, options?: any): AxiosPromise<Array<Supplier>> {
            return localVarFp.createOrUpdateXeroContact(userId, supplier, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {number} userId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        fetchAccountsFromXero(userId: number, options?: any): AxiosPromise<Accounts> {
            return localVarFp.fetchAccountsFromXero(userId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {number} userId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        fetchSuppliersFromXero(userId: number, options?: any): AxiosPromise<Array<Supplier>> {
            return localVarFp.fetchSuppliersFromXero(userId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {number} userId 
         * @param {string} xeroInvoiceId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getXeroInvoiceById(userId: number, xeroInvoiceId: string, options?: any): AxiosPromise<InvoicePayment> {
            return localVarFp.getXeroInvoiceById(userId, xeroInvoiceId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {number} userId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        syncAccountCosesFromXero(userId: number, options?: any): AxiosPromise<Array<AccountCode>> {
            return localVarFp.syncAccountCosesFromXero(userId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {number} userId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        syncSuppliersFromXero(userId: number, options?: any): AxiosPromise<Array<Supplier>> {
            return localVarFp.syncSuppliersFromXero(userId, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * XeroSyncControllerApi - object-oriented interface
 * @export
 * @class XeroSyncControllerApi
 * @extends {BaseAPI}
 */
export class XeroSyncControllerApi extends BaseAPI {
    /**
     * 
     * @param {number} userId 
     * @param {Array<Supplier>} [supplier] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof XeroSyncControllerApi
     */
    public createOrUpdateXeroContact(userId: number, supplier?: Array<Supplier>, options?: RawAxiosRequestConfig) {
        return XeroSyncControllerApiFp(this.configuration).createOrUpdateXeroContact(userId, supplier, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {number} userId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof XeroSyncControllerApi
     */
    public fetchAccountsFromXero(userId: number, options?: RawAxiosRequestConfig) {
        return XeroSyncControllerApiFp(this.configuration).fetchAccountsFromXero(userId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {number} userId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof XeroSyncControllerApi
     */
    public fetchSuppliersFromXero(userId: number, options?: RawAxiosRequestConfig) {
        return XeroSyncControllerApiFp(this.configuration).fetchSuppliersFromXero(userId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {number} userId 
     * @param {string} xeroInvoiceId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof XeroSyncControllerApi
     */
    public getXeroInvoiceById(userId: number, xeroInvoiceId: string, options?: RawAxiosRequestConfig) {
        return XeroSyncControllerApiFp(this.configuration).getXeroInvoiceById(userId, xeroInvoiceId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {number} userId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof XeroSyncControllerApi
     */
    public syncAccountCosesFromXero(userId: number, options?: RawAxiosRequestConfig) {
        return XeroSyncControllerApiFp(this.configuration).syncAccountCosesFromXero(userId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {number} userId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof XeroSyncControllerApi
     */
    public syncSuppliersFromXero(userId: number, options?: RawAxiosRequestConfig) {
        return XeroSyncControllerApiFp(this.configuration).syncSuppliersFromXero(userId, options).then((request) => request(this.axios, this.basePath));
    }
}



