
import { Component, Vue } from 'vue-property-decorator'
import { Action, Getter, State } from 'vuex-class'

import type { Account, AccountSettings } from '@/client/users'
import { AccountAccountTierEnum } from '@/client/users'
import type { Subscription } from '@/modules/billing/types/Subscription'
import type { IUser } from '@/modules/user/types'

import AppLogo from './AppLogo.vue'
import AppCreateNewDialog from './dialogs/AppCreateNewDialog.vue'

const namespaces = {
  user: 'user',
  accountSettings: 'accountSettings',
  app: 'app'
}

@Component({
  components: {
    AppLogo,
    AppCreateNewDialog
  }
})
export default class AppDrawer extends Vue {
  @State('darkMode', { namespace: namespaces.app }) darkMode?: boolean
  @State('subscription', { namespace: 'user' }) subscription!: Subscription
  @State('user', { namespace: namespaces.user }) user!: IUser
  @State('accountSettings', { namespace: namespaces.accountSettings }) accountSettings!: AccountSettings

  @Getter('isViewer', { namespace: namespaces.user }) isViewer!: boolean
  @Getter('isSysAdmin', { namespace: namespaces.user }) isSysAdmin!: boolean
  @Getter('currentAccount', { namespace: namespaces.user }) currentAccount!: Account
  @Getter('isPoAndInventoryEnabled', { namespace: namespaces.accountSettings }) isPoAndInventoryEnabled!: boolean
  @Getter('isAdmin', { namespace: namespaces.user }) isAdmin!: boolean

  @Action('getAccountSettings', { namespace: namespaces.accountSettings }) getAccountSettings!: () => Promise<void>

  showCreateDialog = false
  async beforeMount () {
    await Promise.all([this.accountSettings ? null : this.getAccountSettings()])
  }

  get managementMenu () {
    return [
      {
        name: 'management',
        active: this.isManagementSectionEnabled,
        upgrade: !this.isManagementSectionEnabled,
        subItems: [
          { name: 'suppliers', icon: 'mdi-card-account-mail', disabled: !this.isManagementSectionEnabled },
          { name: 'products', icon: 'mdi-tag-multiple', disabled: !this.isManagementSectionEnabled },
          this.isPoAndInventoryEnabled ? { name: 'invoices', icon: 'mdi-receipt-text-outline', disabled: !this.isManagementSectionEnabled } : { name: 'invoicesWithNoPO', icon: 'mdi-receipt-text-outline', disabled: !this.isManagementSectionEnabled },
          this.isOrdersAndShippingEnabled ? { name: 'orders', icon: 'mdi-package-variant', disabled: !this.isManagementSectionEnabled } : null,
          { name: 'bills', icon: 'mdi-currency-usd', disabled: !this.isManagementSectionEnabled }
        ].filter(Boolean)
      }
    ]
  }

  get reportingMenu () {
    return [
      {
        name: 'reporting',
        active: false,
        subItems: [
          { name: 'profit', icon: 'mdi-cash-multiple', disabled: true },
          { name: 'revenue', icon: 'mdi-finance', disabled: true },
          { name: 'turnover', icon: 'mdi-chart-pie', disabled: true },
          { name: 'statistics', icon: 'mdi-poll', disabled: true },
          { name: 'trends', icon: 'mdi-chart-line-variant', disabled: true }
        ]
      }
    ]
  }

  get settingsMenu () {
    return [
      {
        name: 'settings',
        active: false,
        subItems: [
          { name: 'profile', icon: 'mdi-account' },
          (this.isAdmin && !this.isBillingLifetime) ? { name: 'billing', icon: 'mdi-credit-card' } : null,
          this.isAdmin ? { name: 'users', icon: 'mdi-account-multiple' } : null,
          this.isAdmin ? { name: 'integrations', icon: 'mdi-power-plug' } : null,
          this.isAdmin ? { name: 'accountSettings', icon: 'mdi-cog' } : null,
          { name: 'notifications', icon: 'mdi-bell-outline' },
          { name: 'support', icon: 'mdi-lifebuoy', external: 'mailto:support@sellervue.com' }
        ].filter(Boolean)
      }
    ]
  }

  get system () {
    return [
      {
        name: 'system',
        active: false,
        subItems: [
          this.isSysAdmin ? { name: 'userManagement', icon: 'mdi-account-group' } : null
          // { name: 'notifications', icon: 'mdi-bell-outline' }
        ].filter(Boolean)
      }
    ]
  }

  get marketplacesMenu () {
    return [
      {
        name: 'marketplaces',
        active: !this.isManagementSectionEnabled,
        subItems: [
          this.isFeeTrackerActive ? { name: 'feeTrackerPro', icon: 'mdi-chart-line' } : null,
          this.isSv1Enabled ? { name: 'marketplaceOverview', icon: 'mdi-chart-line' } : null,
          this.isSv1Enabled ? { name: 'ServiceFees', icon: 'mdi-chart-line' } : null,
          this.isSv1Enabled ? { name: 'ProductSales', icon: 'mdi-chart-line' } : null,
          this.isSv1Enabled ? { name: 'ProductProfitability', icon: 'mdi-chart-line' } : null,
          this.isSv1Enabled ? { name: 'ProductOrderMetrics', icon: 'mdi-chart-line' } : null
        ].filter(Boolean)
      }
    ]
  }

  get navigationItems () {
    return [
      ...this.managementMenu,
      ...this.marketplacesMenu,
      ...this.settingsMenu,
      ...this.system
    ]
  }

  get isOrdersAndShippingEnabled (): boolean {
    return this.accountSettings?.isOrdersAndShippingEnabled || false
  }

  get isBillingLifetime (): boolean {
    return this.currentAccount.accountTier === AccountAccountTierEnum.Lifetime || false
  }

  get isManagementSectionEnabled (): boolean {
    return this.accountSettings?.isManagementSectionEnabled || false
  }

  get isFeeTrackerActive (): boolean {
    return this.accountSettings?.isFeeTrackerActive || false
  }

  get isSv1Enabled (): boolean {
    return this.accountSettings?.isSv1Enabled || false
  }
}

