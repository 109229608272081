/* tslint:disable */
/* eslint-disable */
/**
 * OpenAPI definition
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import type { Configuration } from './configuration';
import type { AxiosPromise, AxiosInstance, RawAxiosRequestConfig } from 'axios';
import globalAxios from 'axios';
// Some imports not used depending on template conditions
// @ts-ignore
import { DUMMY_BASE_URL, assertParamExists, setApiKeyToObject, setBasicAuthToObject, setBearerAuthToObject, setOAuthToObject, setSearchParams, serializeDataIfNeeded, toPathString, createRequestFunction } from './common';
import type { RequestArgs } from './base';
// @ts-ignore
import { BASE_PATH, COLLECTION_FORMATS, BaseAPI, RequiredError, operationServerMap } from './base';

/**
 * 
 * @export
 * @interface AuthToken
 */
export interface AuthToken {
    /**
     * 
     * @type {number}
     * @memberof AuthToken
     */
    'id'?: number;
    /**
     * 
     * @type {number}
     * @memberof AuthToken
     */
    'userId'?: number;
    /**
     * 
     * @type {number}
     * @memberof AuthToken
     */
    'accountId'?: number;
    /**
     * 
     * @type {string}
     * @memberof AuthToken
     */
    'access_token'?: string;
    /**
     * 
     * @type {number}
     * @memberof AuthToken
     */
    'expires_in'?: number;
    /**
     * 
     * @type {number}
     * @memberof AuthToken
     */
    'refresh_expires_in'?: number;
    /**
     * 
     * @type {string}
     * @memberof AuthToken
     */
    'refresh_token'?: string;
    /**
     * 
     * @type {string}
     * @memberof AuthToken
     */
    'token_type'?: string;
    /**
     * 
     * @type {string}
     * @memberof AuthToken
     */
    'id_token'?: string;
    /**
     * 
     * @type {string}
     * @memberof AuthToken
     */
    'session_state'?: string;
    /**
     * 
     * @type {string}
     * @memberof AuthToken
     */
    'scope'?: string;
}
/**
 * 
 * @export
 * @interface LoginRequest
 */
export interface LoginRequest {
    /**
     * 
     * @type {string}
     * @memberof LoginRequest
     */
    'userName'?: string;
    /**
     * 
     * @type {string}
     * @memberof LoginRequest
     */
    'password'?: string;
}

/**
 * GatewayControllerApi - axios parameter creator
 * @export
 */
export const GatewayControllerApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @param {LoginRequest} [loginRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        authenticate: async (loginRequest?: LoginRequest, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/gateway/authenticate`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(loginRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} userId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getUserSessionAuthToken: async (userId: number, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'userId' is not null or undefined
            assertParamExists('getUserSessionAuthToken', 'userId', userId)
            const localVarPath = `/gateway/{userId}/token`
                .replace(`{${"userId"}}`, encodeURIComponent(String(userId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * GatewayControllerApi - functional programming interface
 * @export
 */
export const GatewayControllerApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = GatewayControllerApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @param {LoginRequest} [loginRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async authenticate(loginRequest?: LoginRequest, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<AuthToken>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.authenticate(loginRequest, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['GatewayControllerApi.authenticate']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @param {number} userId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getUserSessionAuthToken(userId: number, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<AuthToken>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getUserSessionAuthToken(userId, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['GatewayControllerApi.getUserSessionAuthToken']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
    }
};

/**
 * GatewayControllerApi - factory interface
 * @export
 */
export const GatewayControllerApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = GatewayControllerApiFp(configuration)
    return {
        /**
         * 
         * @param {LoginRequest} [loginRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        authenticate(loginRequest?: LoginRequest, options?: any): AxiosPromise<AuthToken> {
            return localVarFp.authenticate(loginRequest, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {number} userId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getUserSessionAuthToken(userId: number, options?: any): AxiosPromise<AuthToken> {
            return localVarFp.getUserSessionAuthToken(userId, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * GatewayControllerApi - object-oriented interface
 * @export
 * @class GatewayControllerApi
 * @extends {BaseAPI}
 */
export class GatewayControllerApi extends BaseAPI {
    /**
     * 
     * @param {LoginRequest} [loginRequest] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof GatewayControllerApi
     */
    public authenticate(loginRequest?: LoginRequest, options?: RawAxiosRequestConfig) {
        return GatewayControllerApiFp(this.configuration).authenticate(loginRequest, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {number} userId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof GatewayControllerApi
     */
    public getUserSessionAuthToken(userId: number, options?: RawAxiosRequestConfig) {
        return GatewayControllerApiFp(this.configuration).getUserSessionAuthToken(userId, options).then((request) => request(this.axios, this.basePath));
    }
}



