
import { Component, Prop, Ref, Vue } from 'vue-property-decorator'
import { Action, Getter, State } from 'vuex-class'

import type { Account, AccountUserSettings, User } from '@/client/users'
import AppSwitchUserDialog from '@/components/dialogs/AppSwitchUserDialog.vue'
import AppUserInviteDialog from '@/components/dialogs/AppUserInviteDialog.vue'
import { themes } from '@/plugins/vuetify-theme'

const namespaces = {
  app: 'app',
  auth: 'auth',
  user: 'user',
  toasts: 'toasts'
}
@Component({
  components: {
    AppSwitchUserDialog,
    AppUserInviteDialog
  }
})
export default class UserMenu extends Vue {
  @State('darkMode', { namespace: namespaces.app }) darkMode?: boolean
  @Action('toggleDarkMode', { namespace: namespaces.app }) toggleDarkMode: any
  @Action('updateaccountUserSettings', { namespace: namespaces.user })
    updateaccountUserSettings!: (opts: { userId: number, accountUserSettings: AccountUserSettings }) => Promise<void>

  @Prop() readonly user!: User

  @Getter('isAdmin', { namespace: namespaces.user }) isAdmin!: boolean

  @Ref() readonly userDialogRef!: AppSwitchUserDialog

  selectedUser!: any
  confirmDialogOpen = false
  inviteDialogOpen = false
  open = false

  logout () {
    this.open = false
    this.$emit('logout')
  }

  toggleDarkModeHandler () {
    this.$setTheme(this.darkMode ? themes.light : themes.dark)
    this.toggleDarkMode()
    this.open = false
  }

  navigate () {
    this.open = false
    this.$router.push({
      name: 'profile'
    })
  }

  async updateSettings (accountSettings: AccountUserSettings) {
    const settings = (({ isFeeTrackerDailyFoundEmailEnabled, isFeeTrackerDailyNotFoundEmailEnabled }) => ({ isFeeTrackerDailyFoundEmailEnabled, isFeeTrackerDailyNotFoundEmailEnabled }))(accountSettings)
    // const settings = (({ isDailySummaryEmailEnabled }) => ({ isDailySummaryEmailEnabled }))(accountSettings)
    await this.updateaccountUserSettings({ userId: accountSettings.userId as number, accountUserSettings: settings })
  }

  showSwitchUserDialog () {
    this.confirmDialogOpen = true
  }

  async switchUserOnClick (selectedUser: Account) {
    this.userDialogRef.selectedUser = selectedUser
    if (selectedUser?.currentAccountUser?.invitationStatus === 'INVITED') {
      this.inviteDialogOpen = true
    } else {
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore
      await this.$refs.userDialogRef.switchUserFn(selectedUser)
      this.open = false
    }
  }

  switchUserOnAcceptance (invitationStatus: string) {
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    this.$refs.userDialogRef.invitationAct(invitationStatus)
    this.inviteDialogOpen = false
  }
}
