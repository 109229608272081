
import { Component, Prop, Vue } from 'vue-property-decorator'

import type { User } from '@/client/users'

@Component
export default class AppUserInviteDialog extends Vue {
  @Prop() shouldShow!: boolean
  @Prop({ default: '' }) title!: string
  @Prop() user!: User
}
